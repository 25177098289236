<template>
  <div>
    <!-- New Register-->
    <div class="row justify-content-end" v-if="!LOCK_SERVICE">
      <div class="col-2">
        <responsive-button icon="plus" text="Nuevo Registro" pill responsive="md" variant="primary" @ClickEvent="OpenModal('registerData')"/>
      </div>
    </div>
    <a-row>
      <services-table-bar @getData="getData"
        :sortByOptions="sortByOptions"
        @searchValue="searchValue = $event"
        @sortByValue="sortByValue = $event"
        @sortDirectionValue="sortDirectionValue = $event"
        @perPageValue="perPageValue = $event"
      />
    </a-row>
    <a-row>
      <a-table
        :scroll="scrollTable"
        :dataSource="tableObject.data"
        :columns="tableObject.columns"
        :loading="tableObject.loading"
        :row-key="record => record.folio"
        :pagination="tableObject.pagination"
        @change="getData"
      >
        <div slot="filterDropdown"
            slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
            class='custom-filter-dropdown'>
          <a-input
            v-ant-ref="c => searchInput = c"
            :placeholder="`Buscar por ${column.title}`"
            :value="selectedKeys[0]"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm)"
            style="width: 188px; margin-bottom: 8px; display: block;"
          />
          <a-button
            type='primary'
            @click="() => handleSearch(selectedKeys, confirm)"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
          >Buscar</a-button>
          <a-button
            @click="() => handleReset(clearFilters)"
            size="small"
            style="width: 90px"
          >Limpiar</a-button>
        </div>
        <a-icon slot="filterIcon" type='search' slot-scope="filtered" :style="{ color: filtered ? '#108ee9' : undefined }"/>
        <template slot="customRender" slot-scope="text">
          <span v-if="searchText">
            <template v-for="(fragment, i) in `${text}`.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark v-if="fragment.toLowerCase() === searchText.toLowerCase()" :key="i" class="highlight">{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{text}}</template>
        </template>
        <!-- Columnas Personalizadas -->
        <div slot="expandedRowRender" slot-scope="data" style="margin: 0">
          <h6 class="text-block mt-1 mb-0 text-center text-uppercase">Comentarios Adicionales</h6>
          <hr class="bg-primary">
          <center>
            <small>{{ data.additional_comment }}</small>
          </center>
        </div>
        <!-- Información de la tabla -->
        <template slot="current" slot-scope="data">
          {{ data.currents.code }} - {{ data.currents.name }}
        </template>
        <template slot="um" slot-scope="data">
          {{ data.ums.sap_code }} - {{ data.ums.name }}
        </template>
        <template slot="container_type" slot-scope="data">
          {{ data.containerTypes.code }} - {{ data.containerTypes.name }}
        </template>
        <template slot="disposal_type" slot-scope="data">
          {{ data.disposalTypes.code }} - {{ data.disposalTypes.name }}
        </template>
        <template slot="programmed_quantity" slot-scope="data">
          <strong>{{ data.container_capacity * data.container_quantity }}</strong>
        </template>
        <!-- Información de la tabla -->
        <template slot="action" slot-scope="data">
          <b-button-group>
            <responsive-button icon="pencil" variant="secondary" pill text="Editar" responsive="md" size="sm" @ClickEvent="OpenModal('editData', data)"/>
            <responsive-button icon="trash" variant="danger" pill text="Eliminar" responsive="md" size="sm" class="ml-2" @ClickEvent="onDelete(data.jsonApi.id)"/>
          </b-button-group>
        </template>
      </a-table>
    </a-row>
    <b-modal title="Registro de Residuo"
             ref="modal-form"
             size="xl"
    >
      <b-container fluid>
        <!-- Form with Model -->
        <a-form-model
          layout="horizontal" :model="form"
          :rules="rules" ref="rule-Form"
        >
          <a-row align="middle" justify="center" class="mb-0">
            <a-col :sm="{ span: 24, }" :md="{ span:6}" >
              <a-form-model-item label="Corriente" class="mx-1" ref="current_id" prop="current_id">
                <a-select v-model="form.current_id" placeholder="Seleccionar" :disabled="infoView"
                                  show-search
                                  option-filter-prop="children"
                                  :filter-option="filterOption" class="small"
                >
                  <a-select-option  v-for="(option) in currents" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                    {{option.name}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :sm="{ span: 24, }" :md="{ span:6}">
              <a-form-model-item label="Unidad de Medida" class="mx-1" ref="u_m_s_id" prop="u_m_s_id">
                <a-select v-model="form.u_m_s_id" placeholder="Seleccionar" :disabled="infoView"
                                  show-search
                                  option-filter-prop="children"
                                  :filter-option="filterOption" class="small"
                >
                  <a-select-option  v-for="(option) in ums" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                    {{option.name}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :sm="{ span: 24, }" :md="{ span:6}">
              <a-form-model-item label="Tipo de Disposición" class="mx-1" ref="disposal_type_id" prop="disposal_type_id">
                <a-select v-model="form.disposal_type_id" placeholder="Seleccionar" :disabled="infoView"
                                  show-search
                                  option-filter-prop="children"
                                  :filter-option="filterOption" class="small"
                >
                  <a-select-option  v-for="(option) in disposalTypes" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                    {{option.name}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :sm="{ span: 24, }" :md="{ span:6}">
              <a-form-model-item label="Tipo de Peligrosidad" class="mx-1"  ref="dangerous_type_id" prop="dangerous_type_id">
                <a-select v-model="form.dangerous_type_id" placeholder="Seleccionar"
                                  show-search
                                  option-filter-prop="children"
                                  :filter-option="filterOption" class="small"
                >
                  <a-select-option  v-for="(option) in dangerousTypes" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                    {{option.name}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row align="middle" justify="center" class="mb-0">
            <a-col :sm="{ span: 24, }" :md="{ span:6}" >
              <a-form-model-item label="Tipo de Contenedor" class="mx-1" ref="container_type_id" prop="container_type_id">
                <a-select v-model="form.container_type_id" placeholder="Seleccionar" :disabled="infoView"
                                  show-search
                                  option-filter-prop="children"
                                  :filter-option="filterOption" class="small"
                >
                  <a-select-option  v-for="(option) in containerTypes" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                    {{option.name}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :sm="{ span: 24, }" :md="{ span:6}">
              <a-form-model-item label="Capacidad del Contenedor" class="mx-1" ref="container_capacity" prop="container_capacity">
                <a-input v-model="form.container_capacity" placeholder="" :disabled="infoView" class="text-center"/>
              </a-form-model-item>
            </a-col>
            <a-col :sm="{ span: 24, }" :md="{ span:6}">
              <a-form-model-item label="Número de Contenedores" class="mx-1" ref="container_quantity" prop="container_quantity">
                <a-input v-model="form.container_quantity" placeholder="" :disabled="infoView" class="text-center"/>
              </a-form-model-item>
            </a-col>
            <a-col :sm="{ span: 24, }" :md="{ span:6}">
              <a-form-model-item label="Cantidad Programada" class="mx-1">
                <a-input v-model="totalQuantity" placeholder="" disabled class="text-center"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row align="middle" justify="center" class="mb-4">
            <a-col span="24" >
              <a-form-model-item label="Comentarios Adicionales" class="mx-1" ref="additional_comment" prop="additional_comment">
                <a-textarea v-model="form.additional_comment" placeholder="Ingrese un comentario adicional al registro del residuo" :rows="4" :disabled="infoView"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <!-- BOTONES -->
          <a-row  align="middle" justify="center" class="mt-4" v-if="!infoView">
            <a-col span="6" offset="10" v-if="!LOCK_SERVICE">
              <a-form-model-item>
                <b-button-group v-show="!load">
                  <responsive-button class="mr-1" variant="primary" pill :text="(form.id === '0') ? 'Guardar' : 'Editar'" responsive="md" icon="archive-fill" @ClickEvent="onSubmit"/>
                  <responsive-button  variant="danger" pill  text="Eliminar"  responsive="md" icon="trash" @ClickEvent="onDelete" v-show="form.id !== '0'"/>
                </b-button-group>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
        <!-- Form with Model -->
        <!-- Spinner -->
        <my-spinner :load="load"/>
      </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseModal"/>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { Modal } from 'ant-design-vue'
import { getGeneralMixin } from '@/mixins/getGeneralMixin'
// import { services } from '@/views/geocycle/service_plannings/data_services'
import { objectArray, resolvePagination } from '@/helpers/jsonApiTransformer'
import servicesTableBar from '@/views/geocycle/service_plannings/components/servicesTableBar'
export default {
  name: 'wasteServiceForm',
  props: {
    folio: {
      default: '',
      type: [String],
      required: true,
    },
    infoView: {
      default: false,
      type: [Boolean],
    },
  },
  mixins: [
    getGeneralMixin,
  ],
  components: {
    servicesTableBar,
  },
  computed: {
    ...mapState(['servicePlannings']),
    ...mapGetters('servicePlannings', ['LOCK_SERVICE']),
    servicePlanningId() {
      return this.$store.state.servicePlannings.servicePlanningId
    },
    totalQuantity() {
      return this.form.container_capacity * this.form.container_quantity
    },
    sortDirection() {
      if (this.sortDirectionValue === '') {
        return ''
      } else if (this.sortDirectionValue === '-') {
        return '-'
      } else {
        return ''
      }
    },
    sortValue() {
      if (this.sortByValue === '') {
        return this.sortByDefaultValue
      } else {
        return this.sortByValue
      }
    },
  },
  data() {
    return {
      // Variable del folio del Tipo de Servicio
      servicePlanningFolio: this.$route.params.folio,
      // <!-- Barra de Control -->
      searchValue: '',
      sortByValue: '',
      sortByDefaultValue: 'container_quantity',
      sortDirectionValue: '',
      sortByOptions: [
        { value: '', text: 'Ordenar por', disabled: true },
        { value: 'folio', text: 'Folio de Seguimiento' },
      ],
      perPageValue: 15,
      // <!-- Barra de Control -->
      // <!-- Tabla -->
      tableObject: {
        data: [],
        columns: [
          {
            title: 'Corriente',
            dataIndex: '',
            key: 'currents',
            align: 'center',
            width: 'auto',
            class: 'small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'current',
            },
            onFilter: (value, record) => record.current.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'UM',
            dataIndex: '',
            key: 'ums',
            align: 'center',
            width: 'auto',
            class: 'small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'um',
            },
            onFilter: (value, record) => record.um.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Tipo de Disposición',
            dataIndex: '',
            key: 'disposalTypes',
            align: 'center',
            width: 'auto',
            class: 'small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'disposal_type',
            },
            onFilter: (value, record) => record.danger_type.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Tipo de Contenedor',
            dataIndex: '',
            key: 'containerTypes',
            align: 'center',
            width: 'auto',
            class: 'small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'container_type',
            },
            onFilter: (value, record) => record.container_type.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Capacidad de Contenedor',
            dataIndex: 'container_capacity',
            key: 'container_capacity\n',
            align: 'center',
            width: 'auto',
            class: 'small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.container_capacity.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Número de Contenedores',
            dataIndex: 'container_quantity',
            key: 'container_quantity',
            align: 'center',
            width: 'auto',
            class: 'small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.container_quantity.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Cantidad Programada',
            dataIndex: '',
            key: 'programmed_quantity',
            align: 'center',
            width: 'auto',
            class: 'small',
            scopedSlots: {
              customRender: 'programmed_quantity',
            },
          },
          {
            title: 'Acciones',
            dataIndex: '',
            key: 'x',
            align: 'center',
            class: 'text-center small',
            scopedSlots: {
              customRender: 'action',
            },
          },
        ],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 30,
          lastPage: 0,
        },
      },
      scrollTable: { x: 1300, y: 1000 },
      // Varialbles propias para el funcionamiento de la tabla
      searchText: '',
      searchInput: null,
      // Varialbles propias para el funcionamiento de la tabla
      // <!-- Tabla -->
      // Form Model
      load: false,
      id: '0',
      resource: 'servicePlanningsMaterials',
      form: {
        id: '0',
        current_id: undefined,
        u_m_s_id: undefined,
        charging_type_id: undefined,
        container_type_id: undefined,
        disposal_type_id: undefined,
        dangerous_type_id: undefined,
        container_capacity: 0,
        container_quantity: 0,
        additional_comment: undefined,
      },
      relationships: {
        servicePlannings: {
          data: { id: 0, type: 'servicePlannings' },
        },
        currents: {
          data: { id: 0, type: 'currents' },
        },
        ums: {
          data: { id: 0, type: 'ums' },
        },
        containerTypes: {
          data: { id: 0, type: 'containerTypes' },
        },
        disposalTypes: {
          data: { id: 0, type: 'disposalTypes' },
        },
        dangerousTypes: {
          data: { id: 0, type: 'dangerousTypes' },
        },
      },
      rules: {
        name: [
          { required: true, message: 'El campo Tipo de Cuenta es obligatorio.', trigger: 'blur' },
          { min: 3, max: 50, message: 'El campo Tipo de Cuenta debe contener entre 3 y 50 caracteres.', trigger: 'blur' },
        ],
        current_id: [
          { required: true, message: 'El campo Corriente es obligatorio.', trigger: 'blur' },
        ],
        u_m_s_id: [
          { required: true, message: 'El campo Unidad de Medida es obligatorio.', trigger: 'blur' },
        ],
        disposal_type_id: [
          { required: true, message: 'El campo Tipo de Disposición es obligatorio.', trigger: 'blur' },
        ],
        dangerous_type_id: [
          { required: true, message: 'El campo Tipo de Peligrosidad es obligatorio.', trigger: 'blur' },
        ],
        container_type_id: [
          { required: true, message: 'El campo Tipo de Contenedor es obligatorio.', trigger: 'blur' },
        ],
        container_capacity: [
          { required: true, message: 'El campo Capacidad del Contenedor es obligatorio.', trigger: 'blur' },
        ],
        container_quantity: [
          { required: true, message: 'El campo Cantidad de Contenedores es obligatorio.', trigger: 'blur' },
        ],
      },
      // Form Model
      // Información de los Catálogos
      currents: [],
      ums: [],
      containerTypes: [],
      chargingTypes: [],
      disposalTypes: [],
      dangerousTypes: [],
    }
  },
  methods: {
    getVuexData() {
      console.log(this.servicePlanningId)
    },
    // Métodos generales de búsqueda
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    // Métodos generales de búsqueda
    // ************************************
    async getMixinCatalogs() {
      // Corrientes
      let params = {
        sort: 'name',
      }
      await this.getResourceData(params, 'currents')
      this.currents = this.generalResponse
      // Corrientes
      // UMS
      params = {
        sort: 'name',
      }
      await this.getResourceData(params, 'ums')
      this.ums = this.generalResponse
      // UMS
      // Tipos de Contenedor
      params = {
        sort: 'name',
      }
      await this.getResourceData(params, 'containerTypes')
      this.containerTypes = this.generalResponse
      // Tipos de Contenedor
      // Tipos de Carga
      params = {
        sort: 'name',
      }
      await this.getResourceData(params, 'chargingTypes')
      this.chargingTypes = this.generalResponse
      // Tipos de Carga
      // Tipos de Disposición
      params = {
        sort: 'name',
      }
      await this.getResourceData(params, 'disposalTypes')
      this.disposalTypes = this.generalResponse
      // Tipos de Disposición
      // Tipos de Peligrosidad
      params = {
        sort: 'name',
      }
      await this.getResourceData(params, 'dangerousTypes')
      this.dangerousTypes = this.generalResponse
      // Tipos de Peligrosidad
    },
    // <!-- Get,Paginate,Search & Filter Data  -->
    getData(paginationEvent = {}) {
      console.log(this.servicePlanningId)
      if (Object.keys(paginationEvent).length !== 0) {
        this.tableObject.pagination.current = paginationEvent.current
        this.tableObject.pagination.pageSize = paginationEvent.pageSize
      }
      this.tableObject.loading = true
      this.$store.dispatch('jv/get', [this.resource, {
        params: {
          'page[number]': (this.searchValue === '') ? `${this.tableObject.pagination.current}` : '1',
          'page[size]': `${this.perPageValue}`,
          'filter[servicePlannings]': `${this.servicePlanningId}`,
          // 'filter[global]': `${this.servicePlanningId}`,
          // sort: `${this.sortDirection}${this.sortValue}`,
          include: 'servicePlannings,currents,ums,containerTypes,disposalTypes,dangerousTypes',
        },
      }])
        .then(response => {
          this.tableObject.loading = false
          const jsonApiPagination = response.jsonApi.json.meta.page
          const tableData = response
          this.tableObject.data = objectArray(tableData)
          console.log(this.tableObject.data)
          this.tableObject.pagination = resolvePagination(jsonApiPagination)
        })
    },
    // <!-- Get,Paginate,Search & Filter Data  -->
    // Table Methods
    handleSearch (selectedKeys, confirm) {
      confirm()
      this.searchText = selectedKeys[0]
    },
    handleReset (clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    // Table Methods
    // Modal
    OpenModal(action, data = {}) {
      this.$refs['modal-form'].show()
      this.getMixinCatalogs()
      if (Object.keys(data).length === 0) {
        this.form.id = '0'
        this.form.current_id = undefined
        this.form.u_m_s_id = undefined
        this.form.charging_type = undefined
        this.form.container_type_id = undefined
        this.form.disposal_type_id = undefined
        this.form.dangerous_type_id = undefined
        this.form.container_capacity = 0
        this.form.container_quantity = 0
        this.form.additional_comment = undefined
      } else {
        this.form.id = data.jsonApi.id
        this.form.current_id = data.currents.jsonApi.id
        this.form.u_m_s_id = data.ums.jsonApi.id
        this.form.container_type_id = data.containerTypes.jsonApi.id
        this.form.disposal_type_id = data.disposalTypes.jsonApi.id
        this.form.dangerous_type_id = data.dangerousTypes.jsonApi.id
        this.form.container_capacity = data.container_capacity
        this.form.container_quantity = data.container_quantity
        this.form.additional_comment = data.additional_comment
      }
    },
    CloseModal() {
      this.resetVariables()
      this.$refs['modal-form'].hide()
    },
    resetVariables() {
      this.form.current_id = undefined
      this.form.u_m_s_id = undefined
      this.form.charging_type_id = undefined
      this.form.container_type_id = undefined
      this.form.disposal_type_id = undefined
      this.form.dangerous_type_id = undefined
      this.form.container_capacity = 0
      this.form.container_quantity = 0
      this.form.additional_comment = undefined
    },
    // Modal
    // Form
    onSubmit() {
      this.$refs['rule-Form'].validate(valid => {
        if (valid) {
          let formObject = {}
          let action = 'post'
          this.relationships.servicePlannings.data.id = this.servicePlanningId
          // this.relationships.servicePlannings.data.id = this.folio
          this.relationships.currents.data.id = this.form.current_id
          this.relationships.ums.data.id = this.form.u_m_s_id
          this.relationships.containerTypes.data.id = this.form.container_type_id
          this.relationships.disposalTypes.data.id = this.form.disposal_type_id
          this.relationships.dangerousTypes.data.id = this.form.dangerous_type_id
          if (this.form.id === '0') {
            // Store Object
            formObject = {
              container_capacity: this.form.container_capacity,
              container_quantity: this.form.container_quantity,
              additional_comment: this.form.additional_comment,
              jsonApi: {
                type: this.resource,
                relationships: this.relationships,
              },
            }
            // Store Object
          } else {
            // Update Object
            action = 'patch'
            formObject = {
              container_capacity: this.form.container_capacity,
              container_quantity: this.form.container_quantity,
              additional_comment: this.form.additional_comment,
              jsonApi: {
                type: this.resource,
                id: this.form.id,
                relationships: this.relationships,
              },
            }
            // Update Object
          }
          // Envio del Objecto
          this.load = true
          this.$store.dispatch(`jv/${action}`, [formObject, {}])
            .then((data) => {
              this.CloseModal()
              Modal.success({
                title: '¡Guardado!',
                content: 'El registro se ha guardado exitosamente',
                zIndex: 3000,
              })
              this.getData()
              this.load = false
            })
            .catch((errors) => {
              console.log(errors)
              this.load = false
            })
          // Envio del Objecto
        } else {
          // Fallo de validación
          return false
        }
      })
    },
    onDelete(id) {
      const me = this
      Modal.confirm({
        title: '¿Esta seguro de eliminar este registro?',
        content: 'Esta operación es irreversible',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        zIndex: 3000,
        onOk() {
          // Delete Object
          const deleteObject = {
            jsonApi: {
              type: me.resource,
              id: id,
            },
          }
          // Delete Object
          me.$store.dispatch('jv/delete', [deleteObject, {}])
            .then((data) => {
              me.CloseModal()
              Modal.success({
                title: '¡Eliminado!',
                content: 'El registro se ha eliminado exitosamente',
                zIndex: 3000,
              })
              me.getData()
              me.load = false
            })
            .catch((errors) => {
              console.log(errors)
              this.load = false
            })
        },
        onCancel() {
          Modal.info({
            title: '¡Información!',
            content: 'No se ha eliminado el registro',
            zIndex: 3000,
          })
        },
      })
    },
    // Form
  },
  mounted() {
    this.getData()
    this.getMixinCatalogs()
  },
}
</script>

<style scoped>
.border-w-3 {
  border-width:3px !important;
}
.border-w-5 {
  border-width:5px !important;
}
</style>
