<template>
<div>
  <div class="air__utils__heading" v-if="$route.params.folio == 0">
    <h5>Nuevo Registro</h5>
  </div>
  <div class="air__utils__heading" v-else>
    <h5>Detalle del Servicio: <span class="text-secondary font-weight-bold" >{{$route.params.folio}}</span></h5>
  </div>
  <my-breadcrumbs :routes="routes"/>
  <!-- NAV TOOLBAR -->
  <servicePlanningNavToolbar v-if="service_folio !== '0'" :serviceId="service_folio"/>
  <!-- NAV TOOLBAR -->
  <!--    Tabs-->
  <div class="row">
    <div class="col">
      <div class="mb-5">
        <a-tabs defaultActiveKey="1" v-model="ActiveKey" @change="TabChange" type="card" size="large">
          <!-- General-->
          <a-tab-pane key="1">
            <span slot="tab">
              <a-icon type="form"/>
              General
            </span>
            <div class="card">
              <div class="card-body text-center">
                <general-service-form :folio="service_folio" :serviceId="serviceId"/>
              </div>
            </div>
          </a-tab-pane>
          <!-- Transporte-->
          <a-tab-pane key="2">
            <span slot="tab">
              <a-icon type="car"/>
              Transporte
            </span>
            <div class="card">
              <div class="card-body text-center">
                <transport-service-form :folio="sapProgrammingCode" :serviceId="carrierServiceId"/>
              </div>
            </div>
          </a-tab-pane>
          <!-- Req Log-->
          <a-tab-pane key="3" v-if="Object.keys(currentService).length > 0 && (currentService.own === true || currentService.own === 1)">
            <span slot="tab">
              <a-icon type="schedule"/>
              Requerimientos Logísticos
            </span>
            <div class="card">
              <div class="card-body text-center">
                <extra-service-form :folio="sapProgrammingCode" :serviceId="extraServiceId"/>
              </div>
            </div>
          </a-tab-pane>
          <!-- Residuos-->
          <a-tab-pane key="4">
            <span slot="tab">
              <a-icon type="gold"/>
              Residuos
            </span>
            <div class="card">
              <div class="card-body text-center">
                <waste-service-form :folio="service_folio" :serviceId="serviceId"/>
              </div>
            </div>
          </a-tab-pane>
          <!-- Incidencias-->
          <a-tab-pane key="6">
            <span slot="tab">
              <a-icon type="warning"/>
              Incidencias
            </span>
            <div class="card">
              <div class="card-body text-center">
                <incidentForm :serviceId="service_folio"/>
              </div>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </div>
  <!--    Tabs-->
</div>
</template>

<script>
import { mapState } from 'vuex'
import generalServiceForm from '../components/serviceForms/generalServiceForm'
import transportServiceForm from '../components/serviceForms/transportServiceForm'
import extraServiceForm from '../components/serviceForms/extraServiceForm'
import wasteServiceForm from '../components/serviceForms/wasteServiceForm'
import incidentForm from '../../incidents/create_incidents/createIncidentTable'
import servicePlanningNavToolbar from '../components/servicePlanningNavToolbar'
import { getGeneralMixin } from '@/mixins/getGeneralMixin'
import { apiRestMixin } from '@/mixins/apiRestMixin'
import { notification } from 'ant-design-vue'
export default {
  name: 'serviceDetails',
  components: {
    generalServiceForm,
    transportServiceForm,
    extraServiceForm,
    wasteServiceForm,
    incidentForm,
    servicePlanningNavToolbar,
  },
  mixins: [getGeneralMixin, apiRestMixin],
  data() {
    return {
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          breadcrumbName: 'Planeación de Servicios',
          aIcon: '',
          name: 'service_plannings',
        },
        {
          breadcrumbName: 'Calendario',
          aIcon: '',
          name: 'generation_service_plannings',
        },
        {
          breadcrumbName: 'Generación de Servicio',
          aIcon: '',
        },
      ],
      ActiveKey: '1',
      service_folio: this.$route.params.folio.toString(),
      serviceId: this.$route.params.id.toString(),
      carrierServiceId: '0',
      extraServiceId: '0',
    }
  },
  computed: {
    ...mapState(['servicePlannings']),
    sapProgrammingCode() {
      return this.$store.state.servicePlannings.sapProgrammingCode
    },
    currentService() {
      return this.$store.state.servicePlannings.currentService
    },
  },
  methods: {
    getMixinCatalogs() {
      this.getTransactionTypesCatalog()
      this.getServiceTypesCatalog()
    },
    TabChange(key) {
      if (this.sapProgrammingCode === '0' && key !== '1') {
        notification.info({
          message: '¡Espera!',
          description: 'Es necesario completar la información General del Servicio',
        })
      }
    },
  },
  async mounted() {
    if (this.service_folio !== '0') {
      const params = {
        include: 'servicePlanningsExtras',
      }
      await this.getSpecificResourceData(params, 'servicePlannings', this.service_folio)
      await this.getServiceAndSetInState(this.service_folio)
      await this.$store.dispatch('servicePlannings/UPDATE_FOLIO', { payload: this.service_folio })
      await this.$store.dispatch('servicePlannings/UPDATE_KEY', { payload: this.service_folio })
      if (Object.keys(this.specificResponse.servicePlanningsExtras).length > 0) {
        this.extraServiceId = `${this.specificResponse.servicePlanningsExtras.jsonApi.id}`
      }
    } else {
      this.carrierServiceId = '0'
      this.extraServiceId = '0'
    }
  },
}
</script>

<style scoped>

</style>
