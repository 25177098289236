<template>
  <div class="mb-4">
    <a-table :columns="columns"
      :dataSource="clientData.data"
      :pagination="false"
      :class="$style.table"
      :loading="clientData.loading"
    >
      <template slot="value" slot-scope="text">
        <span class="font-weight-bold">{{text}}</span>
      </template>
    </a-table>
  </div>
</template>
<script>
export default {
  name: 'ClientInformation',
  props: {
    clientData: {
      data: [Object],
      required: true,
    },
  },
  data () {
    return {
      columns: [
        {
          title: 'Parámetro',
          dataIndex: 'clientParameter',
          className: 'bg-transparent text-gray-6 small',
          scopedSlots: { customRender: 'parameter' },
        },
        {
          title: 'Valor',
          dataIndex: 'clientValue',
          className: 'bg-transparent text-gray-6 small text-center',
          scopedSlots: { customRender: 'value' },
        },
      ],
    }
  },
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
