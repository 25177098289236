<template>
  <b-container fluid v-if="!globalLoad">
    <a-form-model layout="horizontal" :model="form" :rules="rules" ref="rule-Form">
      <h5 class="text-secondary text-left text-capitalize font-weight-bold mt-3">Información General</h5>
      <hr class="bg-primary">
      <a-row class="mb-0">
        <a-col :sm="{ span: 24 }" :md="{ span:12}" :lg="{ span: 8 }">
          <a-form-model-item label="Turno" class="mx-1" ref="shift_id" prop="shift_id">
            <a-select v-model="form.shift_id"
                      placeholder="Seleccionar"
                      :disabled="infoView"
                      show-search
                      :filter-option="filterOption"
            >
              <a-select-option  v-for="(option) in shifts" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                {{option.code}} - {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :sm="{span: 24}" :md="{span: 8}" >
          <a-form-model-item  class="mx-1">
            <a-button :type="material_guards_button_type" size="small" @click="OpenModal('material_guards')" class="mr-2">
              <b-icon icon="check-circle-fill" class="mr-2"/>
              {{ !form.material_guards_check ? 'Habilitar Resguardo' : 'Deshabilitar Resguardo' }}
            </a-button>
            <a-checkbox v-model="form.material_guards_check" disabled>
              <strong :class="`text-${material_guards_text_type}`">
                <b-icon icon="lock-fill" :variant="material_guards_text_type"></b-icon>
                {{ material_guards_text }}
              </strong>
            </a-checkbox>
          </a-form-model-item>
        </a-col>
        <a-col :sm="{span: 24}" :md="{span: 8}" >
          <a-form-model-item  class="mx-1"  label="El Servicio de Transporte es de:">
            <a-switch v-model="form.own"
                      checked-children="Geocycle" un-checked-children="Cliente" default-checked />
          </a-form-model-item>
        </a-col>
      </a-row>
      <b-modal :title="'Resguardo de Material'"
        ref="modal-form" size="lg"
      >
        <b-container fluid>
          <!-- Form with Model -->
          <a-form-model
            ref="ruleFormMaterialGuards"
            :model="formMaterialGuards"
            :rules="rulesMaterialGuards"
            layout="vertical"
            :wrapper-col="{ span: 24}"
          >
            <a-row class="mb-2">
              <a-col>
                <a-checkbox v-model="formMaterialGuards.client_assistant_check">
                  <strong class="text-success">
                    <b-icon icon="people-fill"></b-icon> En presencia del cliente
                  </strong>
                </a-checkbox>
              </a-col>
            </a-row>
            <a-row>
              <h5 class="text-secondary text-left text-capitalize font-weight-bold mt-3">Destrucción Fiscal</h5>
              <hr class="bg-primary">
              <a-col :sm="{ span: 24}" :md="{ span: 12 }">
                <a-form-model-item ref="destroy_dated_at" label="Fecha" prop="destroy_dated_at" required>
                  <a-date-picker format="DD/MM/YYYY" v-model="formMaterialGuards.destroy_dated_at"/>
                </a-form-model-item>
              </a-col>
              <a-col :sm="{ span: 24, }" :md="{ span:8}">
                <a-form-model-item ref="destroy_hour" label="Hora" prop="destroy_hour" required>
                  <a-time-picker v-model="formMaterialGuards.destroy_hour" format="HH:mm"/>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col span="24">
                <a-form-model-item ref="comments" label="Comentarios" prop="comments">
                  <a-input v-model="formMaterialGuards.comments" type="textarea" />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row v-if="!LOCK_SERVICE">
              <div class="row justify-content-center" v-show="!load">
                <responsive-button variant="primary" pill size="sm" :text="(materialGuardsCheck) ? 'Editar':'Guardar'" responsive="md" icon="archive-fill" @ClickEvent="onSubmitMaterialGuards" class="mr-2"/>
                <responsive-button v-show="materialGuardsCheck" variant="danger" pill size="sm" text="Reset" responsive="md" icon="trash" @ClickEvent="onResetMaterialGuards"/>
              </div>
            </a-row>
          </a-form-model>
          <!-- Form with Model -->
          <!-- Spinner -->
          <my-spinner :load="load"/>
        </b-container>
        <template v-slot:modal-footer>
          <simple-modal-footer @ClickEvent="CloseModal"/>
        </template>
      </b-modal>
      <a-row>
        <a-col :sm="{ span: 24 }" :md="{ span:12}" :lg="{ span: 8 }">
          <a-form-model-item label="Planta" class="mx-1" ref="work_center_id" prop="work_center_id">
            <a-select v-model="form.work_center_id"
              placeholder="Seleccionar"
              :disabled="infoView"
              show-search
              :filter-option="filterOption"
            >
              <a-select-option  v-for="(option) in workCenters" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                {{option.code}} - {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :sm="{ span: 24 }" :md="{ span:12}" :lg="{ span: 8 }">
          <a-form-model-item label="Servicio" class="mx-1" ref="service_type_id" prop="service_type_id">
            <a-select v-model="form.service_type_id"
              placeholder="Seleccionar"
              :disabled="infoView"
              show-search
              :filter-option="filterOption"
              @change="handleChangeServiceType"
            >
              <a-select-option  v-for="(option) in serviceTypesCatalog" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <!-- <a-col :sm="{ span: 24 }" :md="{ span: 12}" :lg="{ span: 8 }">
          <a-form-model-item label="No. de Programación de SAP" class="mx-1">
            <a-input v-model="form.sap_programming_code" placeholder="" :disabled="infoView" class="text-center"/>
          </a-form-model-item>
        </a-col> -->
        <a-col :sm="{ span: 24 }" :md="{ span: 12}" :lg="{ span: 8 }">
          <a-form-model-item label="Tipo de Operación" class="mx-1" ref="transaction_type_id" prop="transaction_type_id">
            <a-select v-model="form.transaction_type_id"
              placeholder="Seleccionar"
              :disabled="infoView"
              show-search
              :filter-option="filterOption"
            >
              <a-select-option  v-for="(option) in transactionTypesCatalog" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <!-- Información del cliente -->
      <div v-show="serviceTypeName === SERVICE_ENTRY_SALE || serviceTypeName === SERVICE_ENTRY_BUY || serviceTypeName === SERVICE_DEVOLUTION_EXIT">
        <div v-show="clientData.data.length === 0">
          <h5 class="text-secondary text-left font-weight-bold mt-3">Selección del Cliente y el Contrato</h5>
          <hr class="bg-primary">
          <a-row class="mb-0">
            <detail-catalog-bar @getData="getClientContractData"
                :sortByOptions="sortByOptions"
                @searchValue="searchValue = $event"
                @sortByValue="sortByValue = $event"
                @sortDirectionValue="sortDirectionValue = $event"
                @perPageValue="perPageValue = $event"
                @currentPageValue="currentPageValue = $event"
              />
            <a-col span="24">
              <TableSPClientContract :tableObject="tableObjectClientContract"
                @Modal="getSpecificClient" @Filter="getClientContractData"
                @searchInput="searchInput = $event"
              >
                <div slot="clientDetails" slot-scope="record">
                  <strong>{{record.record.clients.sap_code}}</strong> - <strong>{{record.record.clients.name}}</strong>
                </div>
                <div slot="initialDatedAtDetails" slot-scope="record">
                  <strong>
                    {{ moment(record.record.initial_dated_at).format("MMMM Do YYYY") }}
                  </strong>
                </div>
                <div slot="finalDatedAtAtDetails" slot-scope="record">
                  <strong>
                    {{ moment(record.record.final_dated_at).format("MMMM Do YYYY") }}
                  </strong>
                </div>
              </TableSPClientContract>
            </a-col>
          </a-row>
        </div>
        <h5 class="text-secondary text-left font-weight-bold mt-2">Información del Cliente</h5>
        <hr class="bg-primary">
        <a-row class="mb-0">
          <a-col span="24">
            <a-form-model-item  class="mx-1">
              <a-checkbox disabled v-show="clientData.data.length > 0">
                <strong class="text-success">
                  <b-icon icon="truck" variant="success"></b-icon>
                  <span class="text-success" v-show="form.collector_check == true">
                    El cliente es un recolector
                  </span>
                  <span class="text-danger" v-show="form.collector_check == false">
                    El cliente no es un recolector
                  </span>
                </strong>
              </a-checkbox>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :lg="{ span: 18, offset: 3 }" :md="{ span: 24}">
            <a-row>
              <a-col span="20">
                <h2 class="badge-example">Información del cliente</h2>
              </a-col>
              <a-col span="4" class="mb-4" v-if="!LOCK_SERVICE">
                <responsive-button variant="danger" pill block @ClickEvent="clientReset"
                  icon="trash-fill" responsive="md" text="Reset"/>
              </a-col>
            </a-row>
            <div class="card">
              <div class="card-body">
                <InformationClient :clientData="clientData"></InformationClient>
              </div>
            </div>
          </a-col>
        </a-row>
        <template v-if="clientID !== undefined">
          <h5 class="text-secondary text-left font-weight-bold mt-2">Plantas del Cliente</h5>
          <hr class="bg-primary">
          <a-row>
            <a-col span="20">
              <h2 class="badge-example" v-if="form.workCenterClientId.name !== ''">{{form.workCenterClientId.name}}</h2>
            </a-col>
            <a-col span="4" class="mb-4">
              <responsive-button variant="danger" v-if="!LOCK_SERVICE" text="Reset"
                                 pill block @ClickEvent="form.workCenterClientId = { name: '', value: null }"
                                 icon="trash-fill" responsive="md"/>
            </a-col>
          </a-row>
          <a-row v-if="form.workCenterClientId.value === null">
            <workCenterClientForm  typeTable="select" :clientId="clientID" :block="LOCK_SERVICE"
                                   @Select="selectWorkCenter"/>
          </a-row>
        </template>
      </div>
      <div v-show="serviceTypeName === SERVICE_DISPOSAL_EXIT">
        <hr class="bg-primary">
        <a-col :lg="{ span: 24, }" :md="{ span: 24}">
          <div>
            <a-row>
              <a-col span="24">
                <h2 class="badge-example">Información de los Destinos</h2>
              </a-col>
            </a-row>
            <a-row>
              <a-form-model-item label="Seleccione el Destino" class="mx-1">
                <a-select v-model="form.receiver_id"
                  placeholder="Seleccionar"
                  :disabled="infoView"
                  show-search
                  :filter-option="filterOption"
                >
                  <a-select-option  v-for="(option) in receivers" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                    {{option.code}} - {{option.name}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-row>
          </div>
        </a-col>
      </div>
      <div v-show="serviceTypeName === SERVICE_PASSING_ENTRY || serviceTypeName === SERVICE_PASSING_EXIT">
        <hr class="bg-primary">
        <a-col :lg="{ span: 24, }" :md="{ span: 24}">
          <div>
            <a-row>
              <a-col span="24">
                <h2 class="badge-example">Información de Cliente Interno</h2>
              </a-col>
            </a-row>
            <a-row>
              <a-form-model-item label="Seleccione el Cliente Interno" class="mx-1">
                <a-select v-model="form.internal_client_id"
                  placeholder="Seleccionar"
                  :disabled="infoView"
                  show-search
                  :filter-option="filterOption"
                >
                  <a-select-option  v-for="(option) in internalClients" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                    {{option.code}} - {{option.name}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-row>
          </div>
        </a-col>
      </div>
      <!-- Información del cliente -->
      <h5 class="text-secondary text-left font-weight-bold mt-3">Fechas del Servicio</h5>
      <hr class="bg-primary">
      <a-row class="mb-4">
        <a-col :sm="{ span: 24, }" :md="{ span:12}" class="pr-2 border-right border-w-3 border-primary">
            <h6 class="text-secondary text-left text-capitalize mt-1">Programación</h6>
            <hr>
          <a-col :sm="{ span: 24, }" :md="{ span:16}"  :lg="{ span:12}" >
            <a-form-model-item label="Fecha de Programación" class="mx-1">
              <a-date-picker format="DD/MM/YYYY" v-model="form.program_dated_at" :disabled="formBlockInput.program_dated_at" />
            </a-form-model-item>
          </a-col>
          <a-col :sm="{ span: 24, }" :md="{ span:16}" :lg="{ span:12}">
            <a-form-model-item label="Hora de Programación" class="mx-1">
              <a-time-picker v-model="form.program_hour" format="HH:mm" :disabled="formBlockInput.check_program_hour" />
            </a-form-model-item>
          </a-col>
        </a-col>
        <a-col :sm="{ span: 24, }" :md="{ span:12}" class="pl-2 border-right border-w-3 border-primary">
          <h6 class="text-secondary text-left mt-1">Llegada estimada a planta</h6>
          <hr>
          <a-col :sm="{ span: 24, }" :md="{ span:16}" :lg="{ span:12}">
            <a-form-model-item label="Fecha de llegada estimada a planta" class="mx-1">
              <a-date-picker format="DD/MM/YYYY" v-model="form.coming_estimated_at" :disabled="formBlockInput.check_coming_estimated_at"/>
            </a-form-model-item>
          </a-col>
          <a-col :sm="{ span: 24, }" :md="{ span:16}" :lg="{ span:12}">
            <a-form-model-item label="Hora de llegada estimada a planta" class="mx-1">
              <a-time-picker v-model="form.coming_estimated_hour" format="HH:mm" :disabled="formBlockInput.check_coming_estimated_hour"/>
            </a-form-model-item>
          </a-col>
        </a-col>
      </a-row>
      <a-row class="mb-4">
        <a-col :sm="{ span: 24, }" :md="{ span:12}" class="pr-2 border-right border-w-3 border-primary">
          <h6 class="text-secondary text-left text-capitalize mt-1">Recolección</h6>
          <hr>
          <a-col :sm="{ span: 24, }" :md="{ span:16}" :lg="{ span:12}">
            <a-form-model-item label="Fecha de Recolección" class="mx-1">
              <a-date-picker format="DD/MM/YYYY" v-model="form.pickup_dated_at" :disabled="formBlockInput.check_pickup_dated_at"/>
            </a-form-model-item>
          </a-col>
          <a-col :sm="{ span: 24, }" :md="{ span:16}" :lg="{ span:12}">
            <a-form-model-item label="Hora de Recolección" class="mx-1">
              <a-time-picker v-model="form.pickup_hour" format="HH:mm" :disabled="formBlockInput.check_pickup_hour"/>
            </a-form-model-item>
          </a-col>
        </a-col>
        <a-col :sm="{ span: 24, }" :md="{ span:12}" class="pl-2  border-right border-w-3 border-primary">
          <h6 class="text-secondary text-left text-capitalize mt-1">Llegada</h6>
          <hr>
          <a-col :sm="{ span: 24, }" :md="{ span:16}"  :lg="{ span:12}" >
            <a-form-model-item label="Fecha de Llegada" class="mx-1">
              <a-date-picker format="DD/MM/YYYY" v-model="form.coming_dated_at" :disabled="formBlockInput.check_coming_dated_at"/>
            </a-form-model-item>
          </a-col>
          <a-col :sm="{ span: 24, }" :md="{ span:16}" :lg="{ span:12}">
            <a-form-model-item label="Hora de Llegada" class="mx-1">
              <a-time-picker v-model="form.coming_hour" format="HH:mm" :disabled="formBlockInput.check_coming_hour"/>
            </a-form-model-item>
          </a-col>
        </a-col>
      </a-row>
      <a-row class="mb-4">
        <a-col :sm="{ span: 24, }" :md="{ span:12}" class="pr-2 border-right border-w-3 border-primary">
          <h6 class="text-secondary text-left text-capitalize mt-1">Descarga</h6>
          <hr>
          <a-col :sm="{ span: 24, }" :md="{ span:16}" :lg="{ span:12}">
            <a-form-model-item label="Fecha de Descarga" class="mx-1">
              <a-date-picker format="DD/MM/YYYY" v-model="form.discharge_dated_at" :disabled="formBlockInput.check_discharge_dated_at"/>
            </a-form-model-item>
          </a-col>
          <a-col :sm="{ span: 24, }" :md="{ span:16}" :lg="{ span:12}">
            <a-form-model-item label="Hora de Descarga" class="mx-1">
              <a-time-picker v-model="form.discharge_hour" format="HH:mm" :disabled="formBlockInput.check_discharge_hour"/>
            </a-form-model-item>
          </a-col>
        </a-col>
        <a-col :sm="{ span: 24, }" :md="{ span:12}" class="pl-2  border-right border-w-3 border-primary">
          <h6 class="text-secondary text-left text-capitalize mt-1">Salida</h6>
          <hr class="bg-primary">
          <a-col :sm="{ span: 24, }" :md="{ span:12}">
            <a-form-model-item label="Fecha de Salida" class="mx-1">
              <a-date-picker format="DD/MM/YYYY" v-model="form.departure_dated_at" :disabled="formBlockInput.check_departure_dated_at"/>
            </a-form-model-item>
          </a-col>
          <a-col :sm="{ span: 24, }" :md="{ span:12}">
            <a-form-model-item label="Hora de Salida" class="mx-1">
              <a-time-picker v-model="form.departure_hour" format="HH:mm" :disabled="formBlockInput.check_departure_hour"/>
            </a-form-model-item>
          </a-col>
        </a-col>
      </a-row>

      <h5 class="text-secondary text-left font-weight-bold mt-3">Información del Personal del Servicio</h5>
      <hr class="bg-primary">
      <a-row class="mb-0">
        <a-col :lg="{ span: 12, }" :md="{ span: 24 }">
          <a-form-model-item label="Nombre Ingeniero de Servicio"
            :wrapperCol="{span:24}" :labelCol="{span:24}" class="mx-1"
            ref="user_id" prop="user_id">
            <a-select v-model="form.user_id"
              placeholder="Seleccionar"
              :disabled="infoView"
              show-search
              :filter-option="filterOption"
            >
              <a-select-option  v-for="(option) in serviceEngineersCatalog" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <!-- BOTONES -->
      <hr class="bg-primary">
      <template v-if="!LOCK_SERVICE">
        <a-row class="mt-0" v-show="!load">
          <a-col :span="3" offset="18">
            <responsive-button class="mr-4" block variant="danger" pill text="Eliminar" responsive="md" icon="trash" @ClickEvent="onDelete" v-show="bEditActive"/>
          </a-col>
          <!--        <a-col :span="3">-->
          <!--          <responsive-button class="ml-1" block variant="warning" pill text="Duplicar" responsive="md" icon="files" v-show="bEditActive"/>-->
          <!--        </a-col>-->
          <a-col :span="3" v-show="!bEditActive">
            <responsive-button class="ml-2" block variant="primary" pill text="Guardar" responsive="md" icon="archive-fill" @ClickEvent="onSubmit"/>
          </a-col>
          <a-col :span="3" v-show="bEditActive">
            <responsive-button class="ml-2" block variant="primary" pill text="Editar" responsive="md" icon="archive-fill" @ClickEvent="onSubmit"/>
          </a-col>
        </a-row>
      </template>
    </a-form-model>

    <my-spinner :load="load"/>
  </b-container>
  <b-container v-else>
    <my-spinner :load="globalLoad"/>
  </b-container>
</template>
<script>
import 'moment/locale/es'
import moment from 'moment'
import api from '@/services/axios/index'
import { mapState, mapActions, mapGetters } from 'vuex'
import { notification, Modal } from 'ant-design-vue'
import InformationClient from '@/@kit/widgets/Tables/clientInformation/index.vue'
import { objectArray, resolvePagination } from '@/helpers/jsonApiTransformer'
import DetailCatalogBar from '@/views/geocycle/catalogs/components/searchBar'
import TableSPClientContract from '../../../auxiliar_components/TableSPClientContract.vue'
import workCenterClientForm from '../../../catalogs/work_center_clients/form'
import { getGeneralMixin } from '@/mixins/getGeneralMixin'
import { generalServiceFormMixin } from '@/mixins/serviceFormMixins/generalServiceFormMixin'
// import { apiRestMixin } from '@/mixins/apiRestMixin'
// import modalContent from '../../../catalogs/collectors/components/modalContent'
export default {
  components: {
    TableSPClientContract,
    DetailCatalogBar,
    InformationClient,
    workCenterClientForm,
    // modalContent,
  },
  name: 'generalServiceForm',
  props: {
    folio: {
      default: '0',
      type: [String],
      required: true,
    },
    serviceId: {
      default: '0',
      type: [String],
      required: true,
    },
    infoView: {
      default: false,
      type: [Boolean],
    },
  },
  mixins: [
    generalServiceFormMixin,
    getGeneralMixin,
  ],
  data() {
    return {
      // Auxiliar and visual variables
      // clientContractPanel: '',
      // SERVICE_TYPES
      SERVICE_ENTRY_SALE: 'Entrada por Venta',
      SERVICE_ENTRY_BUY: 'Entrada por Compra',
      SERVICE_PASSING_ENTRY: 'Entrada por Traspaso',
      SERVICE_PASSING_EXIT: 'Salida por Traspaso',
      SERVICE_DISPOSAL_EXIT: 'Salida por Disposición',
      SERVICE_DEVOLUTION_EXIT: 'Salida por Devolución',
      // SERVICE_PLANNINGS
      SERVICE_STATUS_PENDANT: 'Pendiente',
      SERVICE_STATUS_IN_PLATFORM: 'En Plataforma',
      SERVICE_STATUS_FINISH: 'Concluido',
      SERVICE_STATUS_INCIDENT: 'Con Incidencia',
      SERVICE_STATUS_REPROGRAM: 'Reprogramación',
      SERVICE_STATUS_REJECTED: 'Rechazado',
      // SAP_STATUS
      SAP_STATUS_PENDANT: 'Pendiente',
      SAP_STATUS_PROGRAM: 'Programado',
      SAP_STATUS_REJECTED: 'Rechazado',
      // STATUS
      STATUS_PROGRAM: 'Programado',
      STATUS_NOT_PROGRAM: 'No Programado',
      // Id que se obtiene de la ruta
      IdFromRoute: this.$route.params.id,
      serviceTypeName: '',
      // Objeto perteneciente al cliente
      clientData: {
        data: [],
        loading: false,
      },
      clientResource: 'clients',
      // Objeto perteneciente al formulario de material_guards
      formMaterialGuards: {
        service_planning_id: undefined,
        client_assistant_check: false,
        destroy_dated_at: moment(),
        destroy_hour: moment(),
        comments: undefined,
      },
      // Reglas para el formulario MaterialGuards
      rulesMaterialGuards: {
        destroy_dated_at: [
          { required: true, message: 'Es obligatorio definir una fecha de destrucción', trigger: 'blur' },
        ],
        destroy_hour: [
          { required: true, message: 'Es obligatorio definir una fecha de destrucción', trigger: 'blur' },
        ],
      },
      // <!-- Table -->
      tableObjectClientContract: {
        data: [],
        columns: [
          {
            title: 'Clave del Contrato',
            dataIndex: 'contract_code',
            key: 'contract_code',
            class: 'text-center small',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.contract_code.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Nombre del Cliente',
            dataIndex: 'client_name',
            key: 'client_name',
            class: 'text-center small',
            scopedSlots: {
              customRender: 'clientName',
            },
          },
          {
            title: 'Fecha Inicial',
            dataIndex: 'initial_dated_at',
            key: 'initial_dated_at',
            class: 'text-center small',
            scopedSlots: {
              customRender: 'initialDatedAt',
            },
          },
          {
            title: 'Fecha Final',
            dataIndex: 'final_dated_at',
            key: 'final_dated_at',
            class: 'text-center small',
            scopedSlots: {
              customRender: 'finalDatedAt',
            },
          },
          {
            title: 'Contrato Activo',
            label: 'Activo',
            key: 'is_active',
            class: 'text-center small',
            scopedSlots: {
              customRender: 'isActive',
            },
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            class: 'text-center small',
            scopedSlots: {
              customRender: 'action',
            },
          }],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
      },
      // <!-- Table -->
      // <!-- Barra de Control -->
      searchValue: '',
      sortByValue: '',
      sortByDefaultValue: 'contract_code',
      sortDirectionValue: '',
      sortByOptions: [
        { value: '', text: 'Ordenar por', disabled: true },
        { value: 'contract_code', text: 'Clave de Contrato' },
      ],
      perPageValue: 15,
      currentPageValue: 1,
      // <!-- Barra de Control -->
      // <!-- Variables -->
      initial_dated_at: '',
      final_dated_at: '',
      resource: 'clientContracts',
      resourceName: 'Contratos de Clientes',
      material_guards_text: 'Sin Resguardo de Material',
      material_guards_text_type: 'warning',
      material_guards_button_type: 'primary',
      // Información utilizada en el formulario
      resourceForm: 'servicePlannings',
      resourceFormMaterialGuards: 'materialGuards',
      resourceFormPeriods: 'servicePlanningPeriods',
      resourceFormReceivers: 'servicePlanningReceivers',
      resourceFormName: 'Planeación de Servicios',
      form: {
        shift_id: undefined,
        sap_programming_code: undefined,
        material_guards_check: false,
        work_center_id: undefined,
        client_contract_id: undefined,
        receiver_id: undefined,
        internal_client_id: undefined,
        service_type_id: undefined,
        transaction_type_id: undefined,
        service_planning_status: 'Pendiente',
        sap_status: 'Pendiente',
        status: 'Programado',
        program_dated_at: moment(),
        program_hour: moment(),
        coming_estimated_at: moment(),
        coming_estimated_hour: moment(),
        pickup_dated_at: moment(),
        pickup_hour: moment(),
        coming_dated_at: moment(),
        coming_hour: moment(),
        discharge_dated_at: moment(),
        discharge_hour: moment(),
        departure_dated_at: moment(),
        departure_hour: moment(),
        user_id: undefined,
        collector_check: false,
        own: true,
        workCenterClientId: { name: '', value: null },
      },
      formBlockInput: {
        check_program_dated_at: false,
        check_program_hour: false,
        check_coming_estimated_at: false,
        check_coming_estimated_hour: false,
        check_pickup_dated_at: false,
        check_pickup_hour: false,
        check_coming_dated_at: false,
        check_coming_hour: false,
        check_discharge_dated_at: false,
        check_discharge_hour: false,
        check_departure_dated_at: false,
        check_departure_hour: false,
      },
      shifts: [],
      internalClients: [],
      workCenters: [],
      receivers: [],
      formReceiver: {
        service_planning_id: undefined,
        receiver_id: undefined,
      },
      formInternalClients: {
        service_planning_id: undefined,
        internal_client_id: undefined,
      },
      formData: {},
      rules: {
        shift_if: [
          { required: true, message: 'Es obligatorio elegir un turno', trigger: 'blur' },
        ],
        work_center_id: [
          { required: true, message: 'Es obligatorio elegir una planta', trigger: 'blur' },
        ],
        service_type_id: [
          { required: true, message: 'Es obligatorio elegir un tipo de servicio', trigger: 'blur' },
        ],
        client_contract_id: [
          { required: true, message: 'Es obligatorio elegir un contrato y un cliente', trigger: 'blur' },
        ],
        transaction_type_id: [
          { required: true, message: 'Es obligatorio elegir un tipo de transacción', trigger: 'blur' },
        ],
        program_dated_at: [
          { required: true, message: 'Es obligatorio elegir una fecha de programación', trigger: 'blur' },
        ],
        program_hour: [
          { required: true, message: 'Es obligatorio elegir una hora de programación', trigger: 'blur' },
        ],
        pickup_dated_at: [
          { required: true, message: 'Es obligatorio elegir una hora de recolección', trigger: 'blur' },
        ],
        pickup_hour: [
          { required: true, message: 'Es obligatorio elegir una hora de recolección', trigger: 'blur' },
        ],
        user_id: [
          { required: true, message: 'Es obligatorio elegir un ingeniero de servicio', trigger: 'blur' },
        ],
      },
      ruleFormMaterialGuards: {
        destroy_dated_at: [
          { required: true, message: 'Es obligatorio elegir una Fecha de Destrucción Fiscal', trigger: 'blur' },
        ],
        destroy_hour: [
          { required: true, message: 'Es obligatorio elegir una Hora de Destrucción Fiscal', trigger: 'blur' },
        ],
      },
      load: false,
      globalLoad: false,
      loadMG: false,
      // Relaciones
      relationships: {
        clientContracts: {
          data: { id: 0, type: 'clientContracts' },
        },
        serviceTypes: {
          data: { id: 0, type: 'serviceTypes' },
        },
        transactionTypes: {
          data: { id: 0, type: 'transactionTypes' },
        },
        users: {
          data: { id: 0, type: 'users' },
        },
        workCenters: {
          data: { id: 0, type: 'workCenters' },
        },
      },
      relationshipsPeriods: {
        servicePlanning: {
          data: { id: 0, type: 'servicePlannings' },
        },
      },
      relationshipsMaterialGuards: {
        servicePlanning: {
          data: { id: 0, type: 'servicePlannings' },
        },
      },
      relationshipsServicePlanningReceivers: {
        servicePlanning: {
          data: { id: 0, type: 'servicePlannings' },
        },
        receiver: {
          data: { id: 0, type: 'receivers' },
        },
      },
      // <!-- Variables -->
      // Collectors
      modalForm: false,
      modalData: {},
      clientID: undefined,
      collectorTable: {
        data: [],
        columns: [
          {
            title: 'Recolector',
            dataIndex: '',
            key: 'collector',
            scopedSlots: {
              customRender: 'collector',
            },
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            class: 'text-center',
            scopedSlots: {
              customRender: 'action',
            },
          }],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
      },
      SelectedCollectors: [],
      // Collectors
      // Variable to identify POST or PUT
      bEditActive: false,
    }
  },
  computed: {
    ...mapState('servicePlannings', ['state', 'GET_CLIENT']),
    ...mapGetters('servicePlannings', ['LOCK_SERVICE']),
    sapProgrammingCode() {
      return this.$store.state.servicePlannings.sapProgrammingCode
    },
    servicePlanningId() {
      return this.$store.state.servicePlannings.servicePlanningId
    },
    materialGuardsCheck() {
      return this.$store.state.servicePlannings.materialGuardsCheck
    },
    sortDirection() {
      if (this.sortDirectionValue === '') {
        return ''
      } else if (this.sortDirectionValue === '-') {
        return '-'
      } else {
        return ''
      }
    },
    sortValue() {
      if (this.sortByValue === '') {
        return this.sortByDefaultValue
      } else {
        return this.sortByValue
      }
    },
  },
  methods: {
    ...mapActions('servicePlannings', ['SET_CLIENT']),
    async GetAndPrepareFormView() {
      // Realizará la validación de la información
      if (this.IdFromRoute !== '0') {
        this.globalLoad = true
        const params = {
          include: 'shifts,workCenters,serviceTypes,transactionTypes,users,servicePlanningsPeriods,service_planeable',
        }
        await this.getSpecificResourceData(params, 'servicePlannings', this.IdFromRoute)
        this.formData = this.specificResponse
        // ********************************************************
        // Realizará la vallidación de la información devuelta
        if (Object.keys(this.formData).length !== 0) {
          const assignData = this.formData
          await this.getSpecificClient('onLoadView', assignData)
          this.form.shift_id = assignData.shifts.jsonApi.id
          this.form.service_type_id = assignData.serviceTypes.jsonApi.id
          this.form.sap_programming_code = assignData.sap_programming_code
          this.form.work_center_id = assignData.workCenters.jsonApi.id
          this.form.transaction_type_id = assignData.transactionTypes.jsonApi.id
          this.form.program_dated_at = moment(assignData.servicePlanningsPeriods.program_dated_at)
          this.form.coming_estimated_at = moment(assignData.servicePlanningsPeriods.coming_estimated_at)
          this.form.pickup_dated_at = moment(assignData.servicePlanningsPeriods.pickup_dated_at)
          this.form.coming_dated_at = moment(assignData.servicePlanningsPeriods.coming_dated_at)
          this.form.discharge_dated_at = moment(assignData.servicePlanningsPeriods.discharge_dated_at)
          this.form.departure_dated_at = moment(assignData.servicePlanningsPeriods.departure_dated_at)
          const programHour = `${assignData.servicePlanningsPeriods.program_dated_at.substr(0, 10)} ${assignData.servicePlanningsPeriods.program_hour}`
          const comingEstimatedHour = `${assignData.servicePlanningsPeriods.coming_estimated_at.substr(0, 10)} ${assignData.servicePlanningsPeriods.coming_estimated_hour}`
          const pickupHour = `${assignData.servicePlanningsPeriods.pickup_dated_at.substr(0, 10)} ${assignData.servicePlanningsPeriods.pickup_hour}`
          const comingHour = `${assignData.servicePlanningsPeriods.coming_dated_at.substr(0, 10)} ${assignData.servicePlanningsPeriods.coming_hour}`
          const dischargeHour = `${assignData.servicePlanningsPeriods.discharge_dated_at.substr(0, 10)} ${assignData.servicePlanningsPeriods.discharge_hour}`
          const departureHour = `${assignData.servicePlanningsPeriods.departure_dated_at.substr(0, 10)} ${assignData.servicePlanningsPeriods.departure_hour}`
          this.form.program_hour = moment(programHour)
          this.form.coming_estimated_hour = moment(comingEstimatedHour)
          this.form.pickup_hour = moment(pickupHour)
          this.form.coming_hour = moment(comingHour)
          this.form.discharge_hour = moment(dischargeHour)
          this.form.departure_hour = moment(departureHour)
          this.form.user_id = assignData.jsonApi.relationships.users.data.id
          this.form.own = assignData.own
        }
        this.globalLoad = false
        this.bEditActive = true
        // ********************************************************
        // Realizará la vallidación de la información devuelta
      }
    },
    OpenModal(action, data = {}) {
      this.$refs['modal-form'].show()
      // if (action === 'material_guards') {
      // this.form.material_guards_check = !this.form.material_guards_check
      // if (this.form.material_guards_check === true) {
      // if (this.materialGuardsCheck === true) {
      //   this.$refs['modal-form'].show()
      // }
      // else {
      //   this.material_guards_button_type = 'primary'
      //   this.material_guards_text = 'Sin Resguardo de Material'
      //   this.material_guards_text_type = 'warning'
      // }
      // } else if (action === 'material_guards_details') {
      //   this.$refs['modal-form'].show()
      // }
    },
    CloseModal() {
      // if (this.materialGuardsCheck !== false) {
      //   this.material_guards_button_type = 'primary'
      //   this.material_guards_text = 'Sin Resguardo de Material'
      //   this.material_guards_text_type = 'warning'
      //   this.form.material_guards_check = false
      // }
      this.$refs['modal-form'].hide()
    },
    // Opciones de filtrado para las columnas de la tabla
    handleChange(value) {
      console.log(`selected ${value}`)
    },
    async handleChangeServiceType(value) {
      const params = {}
      await this.getSpecificResourceData(params, 'serviceTypes', value)
      if (Object.keys(this.specificResponse).length > 0) {
        this.serviceTypeName = this.specificResponse.name
        if (this.serviceTypeName === this.SERVICE_ENTRY_SALE || this.serviceTypeName === this.SERVICE_ENTRY_BUY || this.serviceTypeName === this.SERVICE_DEVOLUTION_EXIT) {
          this.bClientContractPanel = true
          this.formBlockInput = {
            check_program_dated_at: false,
            check_program_hour: false,
            check_coming_estimated_at: false,
            check_coming_estimated_hour: false,
            check_pickup_dated_at: false,
            check_pickup_hour: false,
            check_coming_dated_at: true,
            check_coming_hour: true,
            check_discharge_dated_at: true,
            check_discharge_hour: true,
            check_departure_dated_at: true,
            check_departure_hour: true,
          }
        } else if (this.serviceTypeName === this.SERVICE_PASSING_ENTRY) {
          this.bClientContractPanel = false
          this.formBlockInput = {
            check_program_dated_at: false,
            check_program_hour: false,
            check_coming_estimated_at: false,
            check_coming_estimated_hour: false,
            check_pickup_dated_at: true,
            check_pickup_hour: true,
            check_coming_dated_at: false,
            check_coming_hour: false,
            check_discharge_dated_at: true,
            check_discharge_hour: true,
            check_departure_dated_at: false,
            check_departure_hour: false,
          }
        } else {
          this.bClientContractPanel = false
          this.formBlockInput = {
            check_program_dated_at: false,
            check_program_hour: false,
            check_pickup_dated_at: true,
            check_coming_estimated_at: false,
            check_coming_estimated_hour: false,
            check_pickup_hour: true,
            check_coming_dated_at: true,
            check_coming_hour: true,
            check_discharge_dated_at: true,
            check_discharge_hour: true,
            check_departure_dated_at: false,
            check_departure_hour: false,
          }
        }
      }
    },
    // filterOption(input, option) {
    //   return (
    //     option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    //   )
    // },
    // Opciones de filtrado para las columnas de la tabla
    getClientContractData(paginationEvent = {}) {
      if (Object.keys(paginationEvent).length !== 0) {
        this.tableObjectClientContract.pagination.current = paginationEvent.current
        this.tableObjectClientContract.pagination.pageSize = paginationEvent.pageSize
      }
      this.tableObjectClientContract.loading = true
      this.$store.dispatch('jv/get', [this.resource, {
        params: {
          'page[number]': (Object.keys(paginationEvent).length !== 0) ? `${this.tableObjectClientContract.pagination.current}` : `${this.currentPageValue}`,
          'page[size]': `${this.perPageValue}`,
          'filter[global]': `${this.searchValue}`,
          sort: `${this.sortDirection}${this.sortValue}`,
          include: 'clients,clients.workCenters,clients.states,clients.accountingSpecialTypes,clients.accountingTypes,clients.clientTypes',
        },
      }])
        .then(response => {
          this.tableObjectClientContract.loading = false
          const jsonApiPagination = response.jsonApi.json.meta.page
          const tableData = response
          this.tableObjectClientContract.data = objectArray(tableData)
          this.tableObjectClientContract.pagination = resolvePagination(jsonApiPagination)
        })
    },
    // Get Information Client
    async getSpecificClient (action, data = {}) {
      if (!data.is_active && action !== 'onLoadView') {
        Modal.error({
          title: 'Aviso',
          content: 'El servicio no puede ser procesado, debido a que el contrato ya no está activo. Favor de elegir otro contrato.',
          okText: 'Ok',
          centered: true,
        })
      } else {
        if (action === 'onLoadView') {
          const typeC = 'clientContracts'
          const typeIC = 'internalClients'
          // const typeR = 'receivers'
          // Add the validation with de type in JsonApi
          if (data.service_planeable.jsonApi.type === typeC) {
            // Present the Client Information
            // await this.handleChangeServiceType(data.serviceTypes.jsonApi.id)
            this.serviceTypeName = data.serviceTypes.name
            this.bClientContractPanel = true
            this.formBlockInput = {
              check_program_dated_at: false,
              check_program_hour: false,
              check_pickup_dated_at: false,
              check_pickup_hour: false,
              check_coming_dated_at: true,
              check_coming_hour: true,
              check_discharge_dated_at: true,
              check_discharge_hour: true,
              check_departure_dated_at: true,
              check_departure_hour: true,
            }
            this.clientReset()
            this.clientData.loading = true
            const params = {
              include: 'clients,clients.workCenters,clients.states,clients.accountingSpecialTypes,clients.accountingTypes,clients.clientTypes',
            }
            await this.getSpecificResourceData(params, 'clientContracts', data.service_planeable.jsonApi.id)
            const newTableData = this.specificResponse
            this.form.client_contract_id = newTableData.jsonApi.id
            this.clientID = newTableData.clients.jsonApi.id
            this.SET_CLIENT(newTableData.clients.code)
            // Set variables for collectors
            const isActive = {
              clientParameter: 'Contrato Activo',
              clientValue: `${data.is_active ? 'SI' : 'NO'}`,
            }
            const objContract = {
              clientParameter: 'Contrato',
              clientValue: `${newTableData.contract_code}`,
            }
            const objDatePeriods = {
              clientParameter: 'Períodos del Contrato',
              clientValue: `Fecha Inicial: ${newTableData.initial_dated_at} | Fecha Final: ${newTableData.final_dated_at}`,
            }
            const clientData = newTableData.clients
            const objClientName = {
              clientParameter: 'Nombre',
              clientValue: `${clientData.code} - ${clientData.name}`,
            }
            const objWorkCenter = {
              clientParameter: 'Centro de Trabajo',
              clientValue: `${clientData.workCenters.code} - ${clientData.workCenters.name}`,
            }
            const clientType = {
              clientParameter: 'Contabilización',
              clientValue: `${clientData.accountingTypes.name}`,
            }
            const objCharacteristic1 = {
              clientParameter: 'Registro Ambiental',
              clientValue: `${clientData.environmental_record}`,
            }
            const objCharacteristic2 = {
              clientParameter: 'Correo Electrónico',
              clientValue: `${clientData.email}`,
            }
            const objCharacteristic3 = {
              clientParameter: 'Teléfono',
              clientValue: `${clientData.phone}`,
            }
            const objState = {
              clientParameter: 'Estado',
              clientValue: `${clientData.states.name}`,
            }
            const objLocalization = {
              clientParameter: 'Localización',
              clientValue: `${clientData.zip_code} - ${clientData.municipality_name}`,
            }
            const objAddress = {
              clientParameter: 'Domicilio',
              clientValue: `${clientData.is_active}`,
            }
            this.clientData.data.push(objContract)
            this.clientData.data.push(objDatePeriods)
            this.clientData.data.push(objClientName)
            this.clientData.data.push(objWorkCenter)
            this.clientData.data.push(clientType)
            this.clientData.data.push(objCharacteristic1)
            this.clientData.data.push(objCharacteristic2)
            this.clientData.data.push(objCharacteristic3)
            this.clientData.data.push(objState)
            this.clientData.data.push(objLocalization)
            this.clientData.data.push(objAddress)
            this.clientData.data.push(isActive)
            this.clientData.loading = false
            this.form.collector_check = newTableData.is_collector
          } else {
            // Present the Internal Client Information
            await this.handleChangeServiceType(data.serviceTypes.jsonApi.id)
            const servicePlaneable = data.service_planeable.jsonApi
            if (data.service_planeable.jsonApi.type === typeIC) {
              this.form.internal_client_id = servicePlaneable.id
            } else {
              this.form.receiver_id = servicePlaneable.id
            }
          }
        } else {
          this.clientReset()
          this.clientData.loading = true
          const contractCode = data.contract_code
          const datePeriods = `Fecha Inicial: ${data.initial_dated_at} | Fecha Final: ${data.final_dated_at}`
          const newTableData = data.clients
          this.form.client_contract_id = data.jsonApi.id
          this.SET_CLIENT(newTableData.code)
          // Set variables for collectors
          const isActive = {
            clientParameter: 'Contrato Activo',
            clientValue: `${data.is_active ? 'SI' : 'NO'}`,
          }
          const objContract = {
            clientParameter: 'Contrato',
            clientValue: `${contractCode}`,
          }
          const objDatePeriods = {
            clientParameter: 'Períodos del Contrato',
            clientValue: datePeriods,
          }
          const objClientName = {
            clientParameter: 'Nombre',
            clientValue: `${newTableData.code} - ${newTableData.name}`,
          }
          const objWorkCenter = {
            clientParameter: 'Centro de Trabajo',
            clientValue: `${newTableData.workCenters.code} - ${newTableData.workCenters.name}`,
          }
          const clientType = {
            clientParameter: 'Contabilización',
            clientValue: `${newTableData.accountingTypes.name}`,
          }
          const objCharacteristic1 = {
            clientParameter: 'Registro Ambiental',
            clientValue: `${newTableData.environmental_record}`,
          }
          const objCharacteristic2 = {
            clientParameter: 'Correo Electrónico',
            clientValue: `${newTableData.email}`,
          }
          const objCharacteristic3 = {
            clientParameter: 'Teléfono',
            clientValue: `${newTableData.phone}`,
          }
          const objState = {
            clientParameter: 'Estado',
            clientValue: `${newTableData.states.name}`,
          }
          const objLocalization = {
            clientParameter: 'Localización',
            clientValue: `${newTableData.zip_code} - ${newTableData.municipality_name}`,
          }
          const objAddress = {
            clientParameter: 'Domicilio',
            clientValue: `${newTableData.address}`,
          }
          this.clientData.data.push(objContract)
          this.clientData.data.push(objDatePeriods)
          this.clientData.data.push(objClientName)
          this.clientData.data.push(objWorkCenter)
          this.clientData.data.push(clientType)
          this.clientData.data.push(objCharacteristic1)
          this.clientData.data.push(objCharacteristic2)
          this.clientData.data.push(objCharacteristic3)
          this.clientData.data.push(objState)
          this.clientData.data.push(objLocalization)
          this.clientData.data.push(objAddress)
          this.clientData.data.push(isActive)
          this.clientData.loading = false
          this.form.collector_check = newTableData.is_collector
        }
      }
    },
    clientReset() {
      this.clientData.data = []
      this.form.collector_check = false
      this.form.workCenterClientId = { name: '', value: null }
    },
    onSubmitMaterialGuards() {
      this.$refs.ruleFormMaterialGuards.validate(valid => {
        if (valid) {
          // Realizará la segunda validación para el almacenamiento directo en BD
          // o en VUEX
          if (this.servicePlanningId === '0') {
            // Realiza el almacenamiento de la información en VUEX
            const payload = {
              materialGuardsCheck: true,
              clientAssistantCheck: this.formMaterialGuards.client_assistant_check,
              destroyDatedAt: moment(this.formMaterialGuards.destroy_dated_at).format('YYYY-MM-DD'),
              destroyHour: moment(this.formMaterialGuards.destroy_hour).format('HH:mm'),
              comments: this.formMaterialGuards.comments,
            }
            this.form.material_guards_check = true
            this.$store.dispatch('servicePlannings/UPDATE_MATERIAL_GUARD_INFO', { payload: payload })
            Modal.success({
              title: '¡Asignado!',
              content: 'El registro de resguardo de material se ha asignado exitosamente',
              zIndex: 3000,
            })
          } else {
            const payload = {
              materialGuardsCheck: true,
              clientAssistantCheck: this.formMaterialGuards.client_assistant_check,
              destroyDatedAt: moment(this.formMaterialGuards.destroy_dated_at).format('YYYY-MM-DD'),
              destroyHour: moment(this.formMaterialGuards.destroy_hour).format('HH:mm'),
              comments: this.formMaterialGuards.comments,
            }
            this.form.material_guards_check = true
            this.$store.dispatch('servicePlannings/UPDATE_MATERIAL_GUARD_INFO', { payload: payload })
            this.saveMaterialGuards(this.servicePlanningId)
          }
          if (this.materialGuardsCheck) {
            // ***********************************************************************
            // ***********************************************************************
            // Se asigna la información
            this.material_guards_text = 'Resguardo de Material Habilitado'
            this.material_guards_button_type = 'danger'
            this.material_guards_text_type = 'success'
            this.CloseModal()
            // Se asigna la información
            // ***********************************************************************
            // ***********************************************************************
          }
        } else {
          return false
        }
      })
    },
    onResetMaterialGuards() {
      const payload = {
        materialGuardsCheck: false,
        clientAssistantCheck: false,
        destroyDatedAt: undefined,
        destroyHour: undefined,
        comments: undefined,
      }
      this.form.material_guards_check = false
      this.material_guards_text = 'Sin Resguardo de Material'
      this.material_guards_button_type = 'primary'
      this.material_guards_text_type = 'warning'
      this.$store.dispatch('servicePlannings/UPDATE_MATERIAL_GUARD_INFO', { payload: payload })
      Modal.info({
        title: '¡Sin Resguardo de Material!',
        content: 'La información del resguardo de material se ha reseteado del formulario',
        zIndex: 3000,
      })
      this.formMaterialGuards = {
        service_planning_id: undefined,
        client_assistant_check: false,
        destroy_dated_at: moment(),
        destroy_hour: moment(),
        comments: undefined,
      }
      this.CloseModal()
    },
    saveMaterialGuards(id) {
      // Realiza el almacenamiento directamente en la Base de Datos
      let formObjectMG = {}
      let action = 'post'
      this.loadMG = true
      // build the relationships with the form data
      // this.relationshipsMaterialGuards.servicePlanning.data.id = this.servicePlanningId
      this.relationshipsMaterialGuards.servicePlanning.data.id = id
      const a = '0'
      if (a === '0') {
        // Store Object
        formObjectMG = {
          client_assistant_check: this.formMaterialGuards.client_assistant_check,
          destroy_dated_at: moment(this.formMaterialGuards.destroy_dated_at).format('YYYY-MM-DD'),
          destroy_hour: moment(this.formMaterialGuards.destroy_hour).format('HH:mm'),
          comments: `${this.formMaterialGuards.comments}`,
          jsonApi: {
            type: this.resourceFormMaterialGuards,
            relationships: this.relationshipsMaterialGuards,
          },
        }
        // Store Object
      } else {
        // Update Object
        action = 'patch'
        formObjectMG = {
          client_assistant_check: this.formMaterialGuards.client_assistant_check,
          destroy_dated_at: moment(this.formMaterialGuards.destroy_dated_at).format('YYYY-MM-DD'),
          destroy_hour: moment(this.formMaterialGuards.destroy_hour).format('HH:mm'),
          comments: `${this.formMaterialGuards.comments}`,
          jsonApi: {
            type: this.resourceFormMaterialGuards,
            relationships: this.relationshipsMaterialGuards,
          },
        }
        // Update Object
      }
      this.resetRelathionshipsMaterialGuards()
      // Envio del Objecto
      this.$store.dispatch(`jv/${action}`, [formObjectMG, {}])
        .then((data) => {
          // Actualiza la información de VUEX
          // console.log(data)
          this.loadMG = false
        })
        .catch(() => {
          this.loadMG = false
        })
    },
    saveServicePlanningReceiver(id) {
      // Realiza el almacenamiento directamente en la Base de Datos
      let formObject = {}
      let action = 'post'
      // build the relationships with the form data
      // this.relationshipsMaterialGuards.servicePlanning.data.id = this.servicePlanningId
      this.relationshipsServicePlanningReceivers.servicePlanning.data.id = id
      this.relationshipsServicePlanningReceivers.receiver.data.id = this.formReceiver.receiver_id
      const a = '0'
      if (a === '0') {
        // Store Object
        formObject = {
          jsonApi: {
            type: this.resourceFormReceivers,
            relationships: this.relationshipsServicePlanningReceivers,
          },
        }
        // Store Object
      } else {
        // Update Object
        action = 'patch'
        formObject = {
          jsonApi: {
            type: this.resourceFormReceivers,
            relationships: this.relationshipsServicePlanningReceivers,
          },
        }
        // Update Object
      }
      this.resetRelathionshipsServicePlanningsReceivers()
      // Envio del Objecto
      this.$store.dispatch(`jv/${action}`, [formObject, {}])
        .then((data) => {
          // Actualiza la información de VUEX
        })
        .catch(() => {
        })
    },
    async getReceivers() {
      const params = {
        sort: 'name',
      }
      await this.getResourceData(params, 'receivers')
      this.receivers = this.generalResponse
    },
    async getShifts() {
      const params = {
        sort: 'code',
      }
      await this.getResourceData(params, 'shifts')
      this.shifts = this.generalResponse
    },
    async getSubstages() {
      this.generalResponse = []
      const params = {
        sort: 'code',
      }
      await this.getResourceData(params, 'workCenters')
      this.workCenters = this.generalResponse
    },
    async getInternalClients() {
      this.generalResponse = []
      const params = {
        sort: 'name',
      }
      await this.getResourceData(params, 'internalClients')
      this.internalClients = this.generalResponse
      // console.log(this.internalClients)
    },
    async getMixinCatalogs() {
      this.getTransactionTypesCatalog()
      this.getServiceTypesCatalog()
      this.getStatesCatalog()
      this.getServiceEngineersCatalog()
      await this.getReceivers()
      await this.getShifts()
      await this.getSubstages()
      await this.getInternalClients()
    },
    onSubmit() {
      this.$refs['rule-Form'].validate(valid => {
        if (valid) {
          this.load = true
          let request = {}
          // Creación StoreObject
          request = {
            id: this.IdFromRoute,
            // POLYMORPHIC DATA
            client_contract_id: this.form.client_contract_id,
            internal_client_id: this.form.internal_client_id,
            receiver_id: this.form.receiver_id,
            // FORM DATA
            shift_id: this.form.shift_id,
            work_center_id: this.form.work_center_id,
            service_type_id: this.form.service_type_id,
            transaction_type_id: this.form.transaction_type_id,
            material_guards_check: this.form.material_guards_check,
            user_id: this.form.user_id,
            service_planning_status: this.SERVICE_STATUS_PENDANT,
            sap_status: this.SAP_STATUS_PENDANT,
            status: this.STATUS_NOT_PROGRAM,
            program_dated_at: this.form.program_dated_at,
            program_hour: moment(this.form.program_hour).format('HH:mm'),
            coming_estimated_at: this.form.coming_estimated_at,
            coming_estimated_hour: moment(this.form.coming_estimated_hour).format('HH:mm'),
            pickup_dated_at: this.form.pickup_dated_at,
            pickup_hour: moment(this.form.pickup_hour).format('HH:mm'),
            coming_dated_at: this.form.coming_dated_at,
            coming_hour: moment(this.form.coming_hour).format('HH:mm'),
            discharge_dated_at: this.form.discharge_dated_at,
            discharge_hour: moment(this.form.discharge_hour).format('HH:mm'),
            departure_dated_at: this.form.departure_dated_at,
            departure_hour: moment(this.form.departure_hour).format('HH:mm'),
            destroy_dated_at: this.formMaterialGuards.destroy_dated_at,
            destroy_hour: this.formMaterialGuards.destroy_hour,
            client_assistant_check: this.formMaterialGuards.client_assistant_check,
            comments: this.formMaterialGuards.comments,
            own: this.form.own,
            // workCenterClient pivot with servicePlannings
            workCenterClientId: this.form.workCenterClientId.value,
          }
          if (this.bEditActive === false) {
            // Send information to ServicePlannings
            api.post('/specialServicePlannings', request)
              .then(response => {
                // Get the role  info
                const responseData = response.data
                Modal.success({
                  title: '¡Guardado!',
                  content: 'Se ha generado correctamente el servicio con el código: ' + responseData.data.servicePlanning.sap_programming_code + '.',
                  zIndex: 3000,
                })
                const sapProgrammingCode = responseData.data.servicePlanning.sap_programming_code
                const programmingCode = responseData.data.servicePlanning.programming_code
                this.$store.dispatch('servicePlannings/UPDATE_FOLIO', { payload: sapProgrammingCode })
                this.$store.dispatch('servicePlannings/UPDATE_KEY', { payload: programmingCode })
                this.getServiceAndSetInState(responseData.data.servicePlanning.sap_programming_code)
                // set the roles  in the form
                this.bEditActive = false
                this.load = false
              })
              .catch(() => {
                this.load = false
              })
          } else {
            api.put('/updateServicePlanning', request)
              .then(response => {
                // Get the role  info
                const responseData = response.data
                Modal.success({
                  title: '¡Editado!',
                  content: 'Se ha editado correctamente el servicio con el código: ' + responseData.data.servicePlanning.sap_programming_code + '.',
                  zIndex: 3000,
                })
                const sapProgrammingCode = responseData.data.servicePlanning.sap_programming_code
                const programmingCode = responseData.data.servicePlanning.programming_code
                this.$store.dispatch('servicePlannings/UPDATE_FOLIO', { payload: sapProgrammingCode })
                this.$store.dispatch('servicePlannings/UPDATE_KEY', { payload: programmingCode })
                this.getServiceAndSetInState(responseData.data.servicePlanning.sap_programming_code)
                // set the roles  in the form
                this.bEditActive = true
                this.load = false
              })
              .catch(() => {
                this.load = false
              })
          }
        } else {
          // Fallo de validación
          return false
        }
      })
    },
    onDelete() {
      const me = this
      Modal.confirm({
        title: '¿Esta seguro de eliminar este registro?',
        content: 'Esta operación es irreversible',
        okText: 'Sí, Eliminar',
        okType: 'danger',
        cancelText: 'No',
        zIndex: 3000,
        onOk() {
          // Delete Object
          const deleteObject = {
            jsonApi: {
              type: me.resourceForm,
              id: me.IdFromRoute,
            },
          }
          // Delete Object
          me.$store.dispatch('jv/delete', [deleteObject, {}])
            .then((data) => {
              Modal.success({
                title: '¡Eliminado!',
                content: 'La información general de Planeación de Servicios se ha eliminado exitosamente',
                zIndex: 3000,
                message: 'Registro eliminado exitosamente',
              })
              me.load = false
              me.$router.push({ name: 'generation_service_plannings' })
            })
            .catch(() => {
              this.load = false
            })
        },
        onCancel() {
          notification.info({
            message: 'No se ha eliminado el registro',
          })
        },
      })
    },
    resetRelathionships() {
      this.relationships = {
        dangerousType: {
          data: { id: 0, type: 'dangerousTypes' },
        },
        workCenter: {
          data: { id: 0, type: 'workCenters' },
        },
        current: {
          data: { id: 0, type: 'currents' },
        },
        rmeRp: {
          data: { id: 0, type: 'rmeRps' },
        },
      }
    },
    resetRelathionshipsPeriods() {
      this.relationshipsPeriods = {
        servicePlanning: {
          data: { id: 0, type: 'servicePlannings' },
        },
      }
    },
    resetRelathionshipsMaterialGuards() {
      this.relationshipsMaterialGuards = {
        servicePlanning: {
          data: { id: 0, type: 'servicePlannings' },
        },
      }
    },
    resetRelathionshipsServicePlanningsReceivers () {
      this.relationshipsServicePlanningReceivers = {
        servicePlanning: {
          data: { id: 0, type: 'servicePlannings' },
        },
        receiver: {
          data: { id: 0, type: 'receivers' },
        },
      }
    },
    resetForm() {
      this.$refs.formMaterialGuards.resetFields()
    },
    // workCenterClients
    selectWorkCenter(data) {
      console.log(data)
      this.form.workCenterClientId.value = data.jsonApi.id
      this.form.workCenterClientId.name = `Cliente: ${data.clients.name} - Planta: ${data.name}`
    },
  },
  mounted() {
    this.getMixinCatalogs()
    this.getClientContractData()
    this.GetAndPrepareFormView()
  },
}
</script>

<style scoped>
  .border-w-3 {
    border-width:3px !important;
  }
  .border-w-5 {
    border-width:5px !important;
  }
</style>
