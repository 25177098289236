<template>
  <b-container fluid>
    <a-form-model layout="vertical" :model="form" :rules="rules" ref="rule-Form">
      <h5 class="text-secondary text-left font-weight-bold mt-0">Evidencias Fotográficas</h5>
      <hr class="bg-primary">
      <a-row align="middle" justify="start" class="mb-0">
        <a-col :sm="{ span: 24, offset: 0 }" :md="{ span: 8, offset: 8 }">
          <a-form-model-item label="El embarque requiere Destrucción Fiscal" class="mx-1" ref="fiscal_destruction_check" prop="fiscal_destruction_check">
            <a-switch v-model="form.fiscal_destruction_check" :disabled="infoView"
              checked-children="Sí, con evidencias fotográficas" un-checked-children="No Aplica"/>
          </a-form-model-item>
        </a-col>
      </a-row>
      <h5 class="text-secondary text-left font-weight-bold mt-0">Contacto del Servicio</h5>
      <hr class="bg-primary">
      <!-- BOTONES -->
       <a-row v-if="!LOCK_SERVICE">
        <a-col :sm="{ span: 12, offset: 0 }" :md="{ span: 12, offset: 0 }" :lg="{ span: 6, offset: 12 }" :xl="{ span: 4, offset: 16 }">
          <responsive-button class="mr-1 mb-2" variant="info" pill block text="Reestablecer Tipo" responsive="md" icon="arrow-repeat" @ClickEvent="RestoreLogisticShipmentType"/>
        </a-col>
         <a-col :sm="{ span: 12 }" :md="{ span: 12 }" :lg="{ span: 6 }" :xl="{ span: 4 }">
          <responsive-button class="ml-2 mb-2" variant="danger" pill block text="Reset" responsive="md" icon="trash-fill" @ClickEvent="ResetLogisticShipmentType"/>
        </a-col>
      </a-row>
      <!-- BOTONES -->
      <!-- INFO DEL CONTACTO -->
      <a-row align="middle" justify="center" class="mb-0">
        <a-col :sm="{ span: 24, }" :md="{ span: 9 }">
          <a-form-model-item label="Nombre del Contacto" class="mx-1">
            <a-input v-model="contactInfo.name" placeholder="" disabled class="text-center"/>
          </a-form-model-item>
        </a-col>
        <a-col :sm="{ span: 24, }" :md="{ span: 9 }">
          <a-form-model-item label="Correo del Contacto" class="mx-1" ref="email" prop="email">
            <a-input v-model="contactInfo.email" placeholder="" disabled class="text-center"/>
          </a-form-model-item>
        </a-col>
        <a-col :sm="{ span: 24, }" :md="{ span: 6 }">
          <a-form-model-item label="No. Teléfono (XX-XXXX-XXXX)" class="mx-1" ref="phone" prop="phone">
            <a-input v-model="contactInfo.phone" placeholder="" disabled class="text-center"/>
          </a-form-model-item>
        </a-col>
      </a-row>
      <!-- INFO DEL CONTACTO -->
      <!-- Se añade el elemento para la selección de contactos y el alta de los mismos -->
      <hr class="bg-primary" v-if="validationContactInfo">
      <a-row align="middle" justify="center" class="mb-0" v-if="validationContactInfo">
        <ContactForm catalogType="select" :clientId="clientId" v-if="!LOCK_SERVICE"/>
      </a-row>
      <!-- Se añade el elemento para la selección de contactos y el alta de los mismos -->
      <h5 class="text-secondary text-left font-weight-bold mt-3">Requerimientos Logísticos</h5>
      <hr class="bg-primary">
      <a-row justify="end" class="my-3"  v-if="!LOCK_SERVICE">
        <a-col :span="3" offset="21">
          <responsive-button text="Nuevo" icon="plus" responsive="md" variant="primary" pill @ClickEvent="OpenModal"/>
        </a-col>
      </a-row>

      <a-table
        :dataSource="servicePlanningRequirementsTable.data"
        :columns="servicePlanningRequirementsCols"
        :loading="servicePlanningRequirementsTable.loading"
        @change="refreshservicePlanningRequirements"
      >
        <div slot="action" slot-scope="data">
          <b-button-group>
            <b-button variant="primary" pill size="sm" title="Editar" @click="OpenModal(data)">
              <b-icon icon="pencil" aria-hidden="true"/>
            </b-button>
          </b-button-group>
        </div>
      </a-table>
      <b-modal title="Requerimiento Logístico" ref="modal-form" size="lg">
        <b-container fluid>
          <!-- Form with Model -->
          <a-form-model ref="rule-Form" layout="vertical"
            :model="spRequirementsForm" :rules="spRequirementsRules"
            :wrapper-col="{ span: 24}"
          >
            <a-form-model-item ref="logistic_requirement_id" label="Requerimiento" prop="logistic_requirement_id" >
              <a-select v-model="spRequirementsForm.logistic_requirement_id"
                        placeholder="Seleccionar Requerimiento"
                        show-search
                        :filter-option="filterOption">
                <a-select-option  v-for="option in logisticRequirements" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                  {{option.name}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="quantity" label="Cantidad" prop="quantity" >
              <a-input-number size="small" :min="0" :step="1" v-model.number="spRequirementsForm.quantity" />
            </a-form-model-item>
            <div class="row justify-content-center" v-show="!load">
              <template v-if="!LOCK_SERVICE">
                <responsive-button variant="primary" pill size="sm" text="Guardar" responsive="md" icon="archive-fill" @ClickEvent="onSubmitSpReq"/>
                <responsive-button v-if="spReqId !== '0'" variant="danger" pill size="sm" text="Eliminar" responsive="md" icon="trash" @ClickEvent="onDeleteSpReq"/>
              </template>
            </div>
          </a-form-model>
          <!-- Form with Model -->

          <!-- Spinner -->
          <my-spinner :load="spReqLoad"/>

        </b-container>
        <template v-slot:modal-footer>
          <simple-modal-footer @ClickEvent="CloseModal"/>
        </template>
      </b-modal>

      <!-- BOTONES -->
      <hr class="bg-primary">
      <a-row align="middle" justify="end" class="mt-0" v-show="!load">
        <a-col :span="4" offset="20"  v-if="!LOCK_SERVICE">
          <responsive-button class="mr-1" variant="primary" pill block text="Guardar" responsive="md" icon="archive-fill" @ClickEvent="onSubmit"/>
        </a-col>
      </a-row>
    </a-form-model>
    <my-spinner :load="load"/>
  </b-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { Modal, notification } from 'ant-design-vue'
import { getGeneralMixin } from '@/mixins/getGeneralMixin'
import { apiRestMixin } from '@/mixins/apiRestMixin'
import ContactForm from '@/views/geocycle/catalogs/contacts/form'
export default {
  name: 'extraServiceForm',
  components: {
    ContactForm,
  },
  mixins: [
    getGeneralMixin, apiRestMixin,
  ],
  props: {
    folio: {
      default: '',
      type: [String],
      required: true,
    },
    serviceId: {
      default: '0',
      type: [String],
      required: true,
    },
    infoView: {
      default: false,
      type: [Boolean],
    },
  },
  computed: {
    ...mapState(['servicePlannings']),
    ...mapGetters('servicePlannings', ['LOCK_SERVICE']),
    servicePlanningId() {
      return this.$store.state.servicePlannings.servicePlanningId
    },
    contactInfo() {
      return this.$store.state.servicePlannings.contactInfo
    },
    clientId() {
      const servicePlaneable = this.$store.state.servicePlannings.currentService.service_planeable

      console.log(servicePlaneable)

      if (servicePlaneable.jsonApi.type === 'clients') {
        return servicePlaneable.jsonApi.code
      } else if (servicePlaneable.jsonApi.type === 'clientContracts') {
        return servicePlaneable.clients.jsonApi.id
      }
      return null
    },
    validationContactInfo() {
      return (Object.keys(this.contactInfo).length === 0)
    },
  },
  data() {
    return {
      contact: {},
      contacts: [],
      resource: 'servicePlanningsExtras',
      formData: {},
      form: {
        id: '0',
        fiscal_destruction_check: false,
        checks: [],
      },
      relationships: {
        servicePlannings: {
          data: { id: 0, type: 'servicePlannings' },
        },
        contacts: {
          data: { id: 0, type: 'contacts' },
        },
      },
      rules: {
        id: [
          { required: true, message: 'El campo de Contacto es obligatorio.', trigger: 'blur' },
        ],
      },
      load: false,
      // Files
      // CheckBoxes
      // Requerimientos Logísticos
      LogRequirementTypes: [],
      LogRequirements: [],
      ServicePlanningRequirementChecked: [],
      // Tabla req Log
      // Tabla req Log
      spReqLoad: false,
      servicePlanningRequirementsTable: {
        data: [],
        loading: false,
        currentPageValue: 1,
        perPageValue: 15,
        pagination: {
          current: 1,
          pageSize: 10,
          from: 0,
          to: 0,
          total: 10,
          lastPage: 0,
        },
      },
      servicePlanningRequirementsCols: [
        // {
        //   title: 'Tipo de Requerimiento',
        //   dataIndex: '',
        //   key: 'requirementType',
        //   align: 'center',
        //   width: 'auto',
        //   class: 'small',
        //   scopedSlots: {
        //     customRender: 'requirementType',
        //   },
        // },
        {
          title: 'Requerimiento',
          dataIndex: 'logisticRequirements.name',
          key: 'logisticRequirements',
          align: 'center',
          class: 'small',
          scopedSlots: {
            customRender: 'logisticRequirements',
          },
        },
        {
          title: 'Cantidad',
          dataIndex: 'quantity',
          key: 'quantity',
          align: 'center',
          class: 'small',
          scopedSlots: {
            customRender: 'quantity',
          },
        },
        {
          title: 'Acciones',
          dataIndex: '',
          key: 'x',
          // fixed: 'right',
          class: 'small',
          align: 'center',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      spReqId: '0',
      spRequirementsForm: {
        logistic_requirement_id: undefined,
        quantity: 0,
      },
      spRequirementsRules: {
        logistic_requirement_id: [
          { required: true, message: 'El campo Requerimiento es obligatorio.', trigger: 'blur' },
        ],
        quantity: [
          { required: true, message: 'El campo Cantidad es obligatorio.', trigger: 'blur' },
          { type: 'integer', message: 'El campo Cantidad debe ser un entero (no se permiten decimales).', trigger: 'blur' },
        ],
      },
      logisticRequirements: [],
    }
  },
  methods: {
    async GetAndPrepareFormView() {
      // Realizará la validación de la información
      if (this.serviceId !== '0') {
        this.globalLoad = true
        const params = {
          include: 'contacts',
        }
        await this.getSpecificResourceData(params, 'servicePlanningsExtras', this.serviceId)
        this.formData = this.specificResponse
        // ********************************************************
        // Realizará la vallidación de la información devuelta
        if (Object.keys(this.formData).length !== 0) {
          const infoData = this.formData
          this.form.id = infoData.jsonApi.id
          this.form.fiscal_destruction_check = infoData.fiscal_destruction_check
          this.relationships.contacts.data.id = infoData.contacts.jsonApi.id
          this.$store.dispatch('servicePlannings/UPDATE_CONTACT_INFO', { payload: infoData.contacts })
        } else {
          this.form.id = '0'
        }
        this.globalLoad = false
        // ********************************************************
        // Realizará la vallidación de la información devuelta
      }
    },
    async getContacts() {
      const params = {
        sort: 'name',
      }
      await this.getResourceData(params, 'contacts')
      this.contacts = this.generalResponse
    },
    RestoreLogisticShipmentType() {
      const infoData = this.formData
      this.$store.dispatch('servicePlannings/UPDATE_CONTACT_INFO', { payload: infoData.contacts })
    },
    ResetLogisticShipmentType() {
      this.$store.dispatch('servicePlannings/UPDATE_CONTACT_INFO', { payload: {} })
    },
    // Checks
    onChangeCheck(e) {
      if (e.target.checked) {
        this.form.checks.push(e.target.value)
      } else {
        const index = this.form.checks.indexOf(e.target.value)
        if (index > -1) {
          this.form.checks.splice(index, 1)
        }
      }
    },
    async getLogRequirementTypes() {
      this.generalResponse = []
      const params = {
        sort: 'name',
      }
      await this.getResourceData(params, 'logisticRequirementTypes')
      this.LogRequirementTypes = this.generalResponse
    },
    getServicePlanningRequirement() {
      const getRelatedObj = {
        jsonApi: {
          type: 'servicePlannings',
          id: this.servicePlanningId,
          relationships: {
            servicePlanningRequirements: undefined,
          },
        },
      }
      this.$store.dispatch('jv/getRelated', getRelatedObj).then(data => {
        if (data) {
          const servicePlanningRequirements = Object.values(data.servicePlanningRequirements.servicePlanningRequirements)
          const mapped = servicePlanningRequirements.map(element => {
            return element.logistic_requirement_id
          })
          this.form.checks = mapped
        }
      })
    },
    setServicePlanningRequirement() {
      const endPoint = {
        jsonApi: {
          type: 'servicePlanningRequirements/set',
        },
      }
      this.roleLoading = true
      this.$store.dispatch('jv/post', [endPoint, {
        params: {
          servicePlanningId: this.servicePlanningId,
          checks: this.form.checks,
        },
      }]).then(() => {
        this.roleLoading = true
        console.log('Get Service')
        this.getServicePlanningRequirement()
        console.log('End Service')
      })
    },
    // Number
    onChangeNumber(value) {
      console.log('changed', value)
    },
    // Switch
    onChangeSwitch(checked) {
      console.log(`a-switch to ${checked}`)
    },
    // Submit
    onSubmit() {
      this.$refs['rule-Form'].validate(valid => {
        if (valid) {
          let formObject = {}
          let action = 'post'

          if (Object.keys(this.contactInfo).length === 0) {
            Modal.error({
              title: 'Error de Validación',
              content: 'Debe Asignar un contacto al servicio',
              zIndex: 3000,
            })
            return
          }
          this.relationships.servicePlannings.data.id = this.servicePlanningId
          this.relationships.contacts.data.id = this.contactInfo.jsonApi.id
          if (this.form.id === '0') {
            // Store Object
            formObject = {
              fiscal_destruction_check: this.form.fiscal_destruction_check,
              jsonApi: {
                type: this.resource,
                relationships: this.relationships,
              },
            }
            // Store Object
          } else {
            // Update Object
            action = 'patch'
            formObject = {
              fiscal_destruction_check: this.form.fiscal_destruction_check,
              jsonApi: {
                id: this.form.id,
                type: this.resource,
                relationships: this.relationships,
              },
            }
            // Update Object
          }
          // Envio del Objecto
          this.load = true
          this.$store.dispatch(`jv/${action}`, [formObject, {}])
            .then((data) => {
              Modal.success({
                title: '¡Guardado!',
                content: 'El registro se ha almacenado exitosamente',
                zIndex: 3000,
              })
              this.load = false
            })
            .catch((errors) => {
              console.log(errors)
              this.load = false
            })
          // Envio del Objecto
        } else {
          // Fallo de validación
          return false
        }
      })
    },
    // Req Log
    refreshservicePlanningRequirements(paginationEvent = {}) {
      const params = {
        'filter[servicePlannings]': this.servicePlanningId,
        include: 'logisticRequirements',
      }
      this.getJsonApiTable(paginationEvent, 'servicePlanningRequirements', this.servicePlanningRequirementsTable, params)
    },
    // <!-- Open & Close Modal Logic  -->
    async getLogisticRequirements() {
      const response = await this.getJsonApiResources('logisticRequirements')
      if (response) {
        this.logisticRequirements = response
      }
    },
    OpenModal(data = {}) {
      this.$refs['modal-form'].show()
      this.getLogisticRequirements()
      if (Object.keys(data).length === 0) {
        //  Store case
        this.spReqId = '0'
        this.spRequirementsForm.quantity = 0
        this.spRequirementsForm.logistic_requirement_id = undefined
      } else {
        // Update case
        this.spReqId = data.jsonApi.id
        this.spRequirementsForm.quantity = data.quantity
        this.spRequirementsForm.logistic_requirement_id = data.logisticRequirements.jsonApi.id
      }
    },
    CloseModal() {
      this.$refs['modal-form'].hide()
      this.spReqId = '0'
      // this.resetForm()
    },
    closeAndRefresh() {
      this.CloseModal()
      this.refreshservicePlanningRequirements()
    },
    // <!-- Open & Close Modal Logic  -->
    // Store & Update
    onSubmitSpReq () {
      this.$refs['rule-Form'].validate(valid => {
        if (valid) {
          let action = 'post'
          const relationships = {
            servicePlannings: {
              data: { id: this.servicePlanningId, type: 'servicePlannings' },
            },
            logisticRequirements: {
              data: { id: this.spRequirementsForm.logistic_requirement_id, type: 'logisticRequirements' },
            },
          }
          const formObject = {
            quantity: this.spRequirementsForm.quantity,
            jsonApi: {
              type: 'servicePlanningRequirements',
              relationships: relationships,
            },
          }
          // console.log(formObject)
          if (this.spReqId !== '0') {
            action = 'patch'
            formObject.jsonApi.id = this.spReqId
          }
          this.spReqLoad = true
          this.setJsonApiResource(formObject, action, {}, () => {
            this.CloseModal()
            this.refreshservicePlanningRequirements()
            notification.success({
              message: 'Se ha registrado el requermiento exitosamente',
            })
          })
          this.spReqLoad = false
        } else return false
      })
    },
    onDeleteSpReq() {
      const me = this
      Modal.confirm({
        title: '¿Esta seguro de eliminar este registro?',
        content: 'Esta operación es irreversible',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        zIndex: 3000,
        onOk() {
          const deleteObject = {
            jsonApi: {
              type: 'servicePlanningRequirements',
              id: me.spReqId,
            },
          }
          me.deleteJsonApiResource(deleteObject, {}, me.closeAndRefresh)
        },
        onCancel() {
          notification.info({
            message: 'No se ha eliminado el registro',
          })
        },
      })
    },
  },
  mounted() {
    this.getContacts()
    this.GetAndPrepareFormView()
    this.refreshservicePlanningRequirements()
    this.getLogisticRequirements()
  },
}
</script>

<style scoped>
.border-w-3 {
  border-width:3px !important;
}
.border-w-5 {
  border-width:5px !important;
}
</style>
