<template>
  <div>
    <a-table
      :scroll="{x:1300}"
      bordered
      :dataSource="tableObject.data"
      :columns="tableObject.columns"
      :loading="tableObject.loading"
      :row-key="record => record.code"
      :pagination="tableObject.pagination"
      @change="refreshResults"
    >
      <div slot="filterDropdown"
           slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
           class='custom-filter-dropdown'>
        <a-input
          v-ant-ref="c => searchInput = c"
          :placeholder="`Buscar por ${column.title}`"
          :value="selectedKeys[0]"
          @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
          @pressEnter="() => handleSearch(selectedKeys, confirm)"
          style="width: 188px; margin-bottom: 8px; display: block;"
        />
        <a-button
          type='primary'
          @click="() => handleSearch(selectedKeys, confirm)"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
        >Buscar</a-button>
        <a-button
          @click="() => handleReset(clearFilters)"
          size="small"
          style="width: 90px"
        >Limpiar</a-button>
      </div>
      <a-icon slot="filterIcon" type='search' slot-scope="filtered" :style="{ color: filtered ? '#108ee9' : undefined }"/>
      <template slot="customRender" slot-scope="text">
        <span v-if="searchText">
          <template v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
            <mark v-if="fragment.toLowerCase() === searchText.toLowerCase()" :key="i" class="highlight">{{fragment}}</mark>
            <template v-else>{{fragment}}</template>
          </template>
        </span>
        <template v-else>{{text}}</template>
      </template>
      <template slot="clientName" slot-scope="text, record">
        <slot name="clientDetails" :record="record"/>
      </template>
      <template slot="initialDatedAt" slot-scope="text, record">
        <slot name="initialDatedAtDetails" :record="record"/>
      </template>
      <template slot="finalDatedAt" slot-scope="text, record">
        <slot name="finalDatedAtAtDetails" :record="record"/>
      </template>
      <template slot="isActive" slot-scope="data">
        <b-icon icon="check-circle-fill" variant="success" v-if="data.is_active==true"/>
        <b-icon icon="x-circle-fill" variant="danger" v-else/>
      </template>
      <div slot="expandedRowRender" slot-scope="record" style="margin: 0">
        <a-row>
          <a-col span="16">
            <strong class="mr-2">Nombre Comercial: </strong> {{ record.clients.business_name }}
          </a-col>
          <a-col span="8">
            <strong class="mr-2">Tipo de Cuenta: </strong> {{ record.clients.accountingSpecialTypes.name }}
          </a-col>
        </a-row>
        <hr>
        <a-row>
          <a-col span="8">
            <strong class="mr-2">Tipo de Cliente: </strong> {{ record.clients.clientTypes.name }}
          </a-col>
          <a-col span="8">
            <strong class="mr-2">Centro de Trabajo: </strong> {{ record.clients.workCenters.code }} - {{ record.clients.workCenters.name }}
          </a-col>
          <a-col span="8">
            <strong class="mr-2">Recolector: </strong>
            <span v-if="record.clients.is_collector === true" class="text-success">
              <b-icon icon="check-circle-fill"></b-icon> SI
            </span>
            <span v-else class="text-danger">
              <b-icon class="danger" icon="x-circle-fill"></b-icon> NO
            </span>
          </a-col>
        </a-row>
        <a-row>
          <a-col span="8">
            <strong>Correo Electrónico: </strong> {{ record.clients.email }}
          </a-col>
          <a-col span="8">
            <strong>No. Teléfonico: </strong> {{ record.clients.phone }}
          </a-col>
          <a-col span="8">
            <strong>Registro Ambiental: </strong> {{ record.clients.environmental_record }}
          </a-col>
        </a-row>
        <hr>
        <a-row>
          <a-col span="8">
            <strong>Estado: </strong> {{ record.clients.states.name }}
          </a-col>
          <a-col span="8">
            <strong>C.P. </strong> {{ record.clients.zip_code }}
          </a-col>
          <a-col span="8">
            <strong>Municipio: </strong> {{ record.clients.municipality_name }}
          </a-col>
        </a-row>
        <a-row>
          <a-col span="24">
            <strong>Dirección: </strong> {{ record.clients.address }}
          </a-col>
        </a-row>
      </div>
      <!-- Columnas Personalizadas -->
      <a slot="action" slot-scope="data">
        <responsive-button variant="info" pill size="sm" responsive="md" text="Seleccionar" icon="check-circle-fill" @ClickEvent="showModal('select_client', data)"/>
      </a>
    </a-table>
  </div>
</template>

<script>
export default {
  name: 'TableSPClientContract',
  props: {
    tableObject: {
      type: [Object],
      required: true,
    },
  },
  data () {
    return {
      // Varialbles propias para el funcionamiento de la tabla
      searchText: '',
      searchInput: null,
      // Varialbles propias para el funcionamiento de la tabla
    }
  },
  watch: {
    searchInput(newValue) {
      this.$emit('searchInput', newValue)
    },
  },
  methods: {
    handleSearch (selectedKeys, confirm) {
      confirm()
      this.searchText = selectedKeys[0]
    },
    handleReset (clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    showModal(action, data = {}) {
      this.$emit('Modal', action, data)
    },
    refreshResults(paginationEvent = {}) {
      this.$emit('Filter', paginationEvent)
    },
  },
  mounted() {
  },
}
</script>

<style scoped>
  .custom-filter-dropdown {
    padding: 8px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
  }
  .highlight {
    background-color: rgb(255, 192, 105);
    padding: 0px;
  }
</style>
